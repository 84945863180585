import React, { useEffect, useState } from 'react'
import { Box, Heading, Image, Stack } from '@chakra-ui/react'
import Logo from "../../assets/images/app_logo.svg";
import DashboardIcon from '../../assets/images/dashboards.svg';
import DashboardWhiteIcon from '../../assets/images/dashboardsWhite.svg';
import ProductIcon from '../../assets/images/byob.svg';
import ProductWhiteIcon from '../../assets/images/byob-white.svg';
import UserCollectionIcon from '../../assets/images/collectionWhite.svg';
import BlackCollectionIcon from '../../assets/images/blackcollection.svg';
import AdminIcon from '../../assets/images/admin.svg';
import ReportIcon from '../../assets/images/report.svg';
import * as routesNames from '../../constants/routes'
import { Link, useLocation } from 'react-router-dom'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

const commonMenu = [
  {
    id: 1,
    menuName: 'Dashboard',
    link: routesNames.ADMINDASHBOARD,
    menuIcon: DashboardIcon,
    menuIconActive: DashboardWhiteIcon,
  },
  {
    id: 2,
    menuName: 'Product',
    link: routesNames.ADMINPRODUCT,
    menuIcon: ProductIcon,
    menuIconActive: ProductWhiteIcon,
  },
  {
    id: 3,
    menuName: 'Collection',
    link: routesNames.ADMINUSERCOLLECTION,
    menuIcon: BlackCollectionIcon,
    menuIconActive: UserCollectionIcon,
  },
]

const adminMenu = [
  {
    id: 4,
    menuName: 'Pre Sanction',
    link: '#',
    subMenu: [
      {
        id: 41,
        subMenuName: 'Borrowers',
        link: routesNames.PRESANCTIONBORROWERS,
      },
      {
        id: 42,
        subMenuName: 'Investors',
        link: routesNames.PRESANCTIONINVESTORS,
      }
    ]
  },
  {
    id: 5,
    menuName: 'Underwritter',
    link: '#',
    subMenu: [
      {
        id: 51,
        subMenuName: 'Loan Applications',
        link: routesNames.UNDERWRITERLOANAPPLICATION,
      }
    ]
  },
  {
    id: 6,
    menuName: 'Post Sanction',
    link: '#',
    subMenu: [
      {
        id: 61,
        subMenuName: 'Loan Agreements',
        link: routesNames.POSTSANCTIONLOANAGREEMENTS,
      }
    ]
  }
]

const reportsMenu = [
  {
    id: 7,
    menuName: 'Internal Report',
    link: routesNames.INTERNALREPORT,
  },
  {
    id: 8,
    menuName: 'Bureau Report',
    link:  routesNames.BUREAUREPORT,
    // subMenu: [
    //   {
    //     id: 81,
    //     subMenuName: 'One Shot Payment',
    //     link: routesNames.ONESHOTPAYMENT,
    //   },
    //   {
    //     id: 82,
    //     subMenuName: 'Multiple EMI Payment',
    //     link: routesNames.MULTIPLEEMIPAYMENT,
    //   },
    //   {
    //     id: 83,
    //     subMenuName: 'CKYC',
    //     link: routesNames.CKYCREPORT,
    //   }
    // ]
  },
  {
    id: 9,
    menuName: 'DCR Report',
    link: routesNames.DCRREPORT,
  }
]

export default function SidebarAdmin() {

  const location = useLocation();
  const [menuId, setMenuId] = useState(null)  
  const [subMenuId, setSubMenuId] = useState(null)  
  const [toggleMenu, setToggleMenu] = useState(false)
  const [toggleSubMenu, setToggleSubMenu] = useState(false)

  useEffect(() => {
    const menuItem = commonMenu.find((item) => item.link === location.pathname);
    const reportMenuItem = reportsMenu.find((item) => item.link === location.pathname);
    let subMenuItem = null;
    if (menuItem) {
      setMenuId(menuItem.id);
      setSubMenuId(null);
    } else if (reportMenuItem) {
      setMenuId(reportMenuItem.id);
      setSubMenuId(null);
    } else {
      adminMenu.forEach((adminItem) => {
        subMenuItem = adminItem.subMenu?.find((subItem) => subItem.link === location.pathname);
        if (subMenuItem) {
          setMenuId(adminItem.id);
          setSubMenuId(subMenuItem.id);
        }
      });
   }
  }, [location.pathname]);

  const handleMenu = (item) => {
    if (menuId !== item) {
      setToggleMenu(true)
    } else {
      setToggleMenu(!toggleMenu)
    }
    setMenuId(item)
  }

  const handleSubMenu = (item) => {
    if (subMenuId !== item) {
      setToggleSubMenu(true)
    } else {
      setToggleSubMenu(!toggleSubMenu)
    }
    setSubMenuId(item)
  }

  return (
    <Box className='sidebar_wrapper admin_sidebar'>
      <Link to={routesNames.ADMINDASHBOARD}>
       <Image src={Logo} alt='logo' className='app_logo' />
      </Link>
      <Stack className="menu_box">
        <Box className='main_menu'>
          {commonMenu?.map((item) => {
            return (
              <Link to={item.link} key={item.id} onClick={() => handleMenu(item.id)}
                className={item.id === menuId ? 'active_main_menu admin_menu_item multiple_items font-poppins' : 'admin_menu_item multiple_items font-poppins'} >
                <Image src={item.id === menuId ? item.menuIconActive : item.menuIcon} className="dashboard_icon" />
                  {item.menuName}
              </Link>
            )
          })}
        </Box>
        <Box className='main_menu'>
          <Box className='menu_label'>
            <Image src={AdminIcon} className="icon_size" />
            <Heading as="h4" className='font-poppins'>Admin</Heading>
          </Box>
          <Box mt={1}>
            {adminMenu?.map((item) => {
              return (
                <Box key={item.id}>
                  <Box mt={2}>
                    <Link to={item.link} onClick={() => handleMenu(item.id)}
                      className={item.id === menuId ? 'active_menu admin_menu_item hover_menu font-poppins' : 'admin_menu_item hover_menu font-poppins'} >
                        {item.menuName}
                        {item.subMenu &&
                          <>
                            {toggleMenu && item.id === menuId ? <ChevronUpIcon /> : <ChevronDownIcon />}
                          </>
                        }
                    </Link>
                  </Box>
                  {toggleMenu &&
                    <Box>
                      {item.id === menuId ? item?.subMenu?.map((elem) => {
                        return (
                          <Box mt={1} mb={1} key={elem.id}>
                            <Link to={elem.link} onClick={() => handleSubMenu(elem.id)}
                              className={elem.id === subMenuId ? "active_menu sub_admin_menu_item hover_menu font-poppins" : "sub_admin_menu_item hover_menu font-poppins"}>
                              {elem.subMenuName}
                            </Link>
                          </Box>
                        )
                      }) : null}
                    </Box>
                  }
                </Box>
              )
            })}
          </Box>
        </Box>
        <Box className='main_menu'>
          <Box className='menu_label'>
            <Image src={ReportIcon} className="icon_size" />
            <Heading as="h4" className='font-poppins'>Reports</Heading>
          </Box>
          <Box mt={1}>
            {reportsMenu?.map((item) => {
              return (
                <Box key={item.id}>
                  <Box mt={2}>
                    <Link to={item.link} onClick={() => handleMenu(item.id)}
                      className={item.id === menuId ? 'active_menu admin_menu_item hover_menu font-poppins' : 'admin_menu_item hover_menu font-poppins'} >
                        {item.menuName}
                        {item.subMenu &&
                          <>
                            {toggleMenu && item.id === menuId ? <ChevronUpIcon /> : <ChevronDownIcon />}
                          </>
                        }
                    </Link>
                  </Box>
                  {toggleMenu &&
                    <Box>
                      {item.id === menuId ? item?.subMenu?.map((elem) => {
                        return (
                          <Box mt={1} mb={1} key={elem.id}>
                            <Link to={elem.link} onClick={() => handleSubMenu(elem.id)}
                              className={elem.id === subMenuId ? "active_menu sub_admin_menu_item hover_menu font-poppins" : "sub_admin_menu_item hover_menu font-poppins"}>
                              {elem.subMenuName}
                            </Link>
                          </Box>
                        )
                      }) : null}
                    </Box>
                  }
                </Box>
              )
            })}
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
