import React from 'react'
import {Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Heading, IconButton, Image, Text} from '@chakra-ui/react'
import CloseIcon from '../../../../../assets/images/close-side.svg'
import CustomButton from '../../../../elements/customButton'
import moment from 'moment'

export default function ClosedLoanSidebar(props) {

    const loanContractId = props?.data?.loanContract[0]?.id
    const repaymentDate = props?.data?.loanContract[0]?.maturity_date && moment(props?.data?.loanContract[0]?.maturity_date).format("DD-MM-YYYY")
    const interestAmount = (props?.data?.product_id === 1) ? props?.data?.loanContract[0]?.accured_interset_on_emi : props?.data?.loanContract[0]?.accured_interset_till_date
    const repaymentAmount = props?.data?.loanContract[0]?.total_repaid_amount

  const PopupBox = () => (
    <Box>
      <DrawerOverlay />
      <DrawerContent>
        <Box className="actions_group">
          <IconButton onClick={props.handleCloseSidebar} className="btn_close_sidebar" icon={<Image src={CloseIcon} />} />
        </Box>
        <DrawerHeader className='sidebar_header'>
            <Box className='loan_type_heading d-flex align-items-center'>
                <Heading as="h5" className='font-poppins'>Loan Application ID</Heading>
                <Text className={props?.data?.product_id === 3 ? "font-poppins table_text loan_insta" : 
                    props?.data?.product_id === 1 ? "font-poppins table_text loan_personal" : 
                    props?.data?.product_id === 2 ? "font-poppins table_text loan_bullet" : 
                    "font-poppins table_text"} >
                    {props?.data?.loan_name}
                </Text>
            </Box>
            <Heading as="h4" className='font-poppins'>{props?.data?.loan_number}</Heading>
        </DrawerHeader>
        <DrawerBody>
            <Box className='sidebar_body_top'>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                    <Text className='font-poppins'>₹ {props?.data?.loanContract[0]?.pledged_amount}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Repayment Date</Heading>
                    <Text className='font-poppins'>{repaymentDate}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Tenure</Heading>
                    <Text className='font-poppins'>{(props?.data?.product_id === 1 ) ? props?.data?.tenure + " Months" : props?.data?.tenure + " Days"}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Interest Rate</Heading>
                    <Text className='font-poppins'>{props?.data?.product_id === 3 ? "0.2% Per Day" : props?.data?.interest_rate + " % Per Annum"}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Interest Amount (Till Date)</Heading>
                    <Text className='font-poppins'>₹ {interestAmount}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Penal Interest Amount</Heading>
                    <Text className='font-poppins'>₹ {props?.data?.loanContract[0]?.penel_interset_till_date}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Total Repaid Amount</Heading>
                    <Text className='font-poppins'>₹ {repaymentAmount}</Text>
                </Box>
            </Box>
        </DrawerBody>
        <Box className='sidebar_footer' mb={3}>
            <Box mt={2} className='full-width'>
                <CustomButton size="md" type="submit" title="View More" className="btn_theme font-poppins" onClick={() => props.handleViewMoreDialogOpen(loanContractId)} />
            </Box>
            <Box mt={2} className='full-width'>
                <CustomButton size="md" type="submit" title="Download Agreement" className="btn_green font-poppins" onClick={() => props.handleDownloadAgreement(props.data?.esign_agreement)} />
            </Box>
        </Box>
      </DrawerContent>
    </Box>
  )

  return (
    <Drawer
      isOpen={props.openSidebar}
      placement="right"
      onClose={props.handleCloseSidebar}
      size={'md'}
    >
      {PopupBox()}
    </Drawer>
  )
}
