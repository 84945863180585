import React, { useEffect, useState } from 'react'
import {Box, Flex, Grid, GridItem, Heading, IconButton, Input, Select, Spacer} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import {useHistory} from 'react-router-dom'
import { CopyIcon } from '@chakra-ui/icons'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import Loader from '../../../elements/loader'
import CustomToast from '../../../elements/customToast'
import copy from 'copy-to-clipboard'
import OnBoardKYC from '../OnBoardKYC/OnBoardKYC'
import CustomButton from '../../../elements/customButton'
import { validateCPUser } from '../../../../services/cpServices'
import { preventInvalidKey, validateMobileNumber } from '../../../../utilities/CustomFunctions'

const userType = [ 
    {
        id: 2,
        name: 'Borrower',
    },
    {
        id: 3,
        name: 'Investor',
    }
]

const CPDashboard = (props) => {

    const { addToast } = CustomToast()
    const cpState = useSelector((state) => state.cpState)
    const adminState = useSelector((state) => state.adminState)
    const masterState = useSelector((state) => state.masterState)
    const authState = useSelector((state) => state.authState)
    const [type, setType] = useState(null)
    const [inputValues, setInputValues] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [userFound, setUserFound] = useState(null)
    const userData = adminState?.getUserByToken?.data?.data
    const dashboardData = cpState?.getCPDashboardDetails?.data?.data
    const dropdownData = masterState?.getDropdownData?.data?.data

    useEffect(() => {
        props.actions.getCPDashboardDetailsAction()
        props.actions.getUserByTokenAction()
        props.actions.getDropdownDataAction({ type: 'employment_type_lender|employment_type_borrower' })
    }, [])

    useEffect(() => {
        if (authState?.createNewUser?.data?.success === true){
          addToast({message: authState?.createNewUser?.data?.message, status: 'success'})
          setUserFound(authState?.createNewUser?.data?.data)
          props?.actions?.cleanUpToast()
        } else if (authState?.createNewUser?.data?.success === false){
          addToast({message: authState?.createNewUser?.data?.message, status: 'error'})
          props?.actions?.cleanUpToast()
        }
    }, [authState?.createNewUser])

    const handleCopy = (link, type) => {
        copy(link, {
            debug: true,
            message: 'Press #{key} to copy',
        })
        setType(type)
        if (type === 'borrower_link') {
            addToast({message: 'Borrower Link Copied', status: 'success'})
        }
        if (type === 'investor_link') {
            addToast({message: 'Investor Link Copied', status: 'success'})
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            const validValue = validateMobileNumber(value, addToast);
            if (validValue !== null) {
              setInputValues({ ...inputValues, [name]: validValue });
            }
        } else {
            setInputValues({ ...inputValues, [name]: value });
        }
    }

    const validateUser = () => {
        if (!inputValues?.user_type) {
            addToast({ message: 'Please Select User Type', status: 'error' })
            return
        }
        if (!inputValues?.employment_type) {
            addToast({ message: `Please Select ${inputValues?.user_type === '2' ? 'Borrower' : 'Investor'} Type`, status: 'error' })
            return
        }
        if (!inputValues?.mobile) {
            addToast({ message: 'Please Enter Mobile Number', status: 'error' })
            return
        }
        let data = {
            mobile: inputValues?.mobile,
        }
        setIsLoading(true)
        validateCPUser(data).then((res) => {
            setIsLoading(false)
            if (res?.data?.status === false) {
                addToast({ message: res?.data?.message, status: 'success' })
                let userObj = {
                    is_cp_user: true,
                    cp_id: userData?.id ?? '',
                    mobile: Number(inputValues.mobile) ?? '',
                    usertype: inputValues.user_type ?? '',
                    employment_type: inputValues.employment_type ?? '',
                    user_device_id: 'NA',
                    platform: 'web',
                }
                console.log(userObj, 'userObj')
                props.actions.createNewUserAction(userObj)
            } else if (res?.data?.status === true) {
                if (res?.data?.data?.is_cp_user === 0) {
                    addToast({message: res?.data?.message, status: 'error'})
                } else if (res?.data?.data?.is_cp_user === 1 && res?.data?.data?.form_completed_status === 1) {
                    addToast({message: res?.data?.message, status: 'error'})
                } else if (res?.data?.data?.is_cp_user === 1 && res?.data?.data?.form_completed_status === 0) {
                    addToast({message: res?.data?.message, status: 'warning'})
                    setInputValues({})
                    setUserFound(res?.data?.data)
                }
            }
            }).catch((error) => {
            addToast({message: error, status: 'error'})
            setIsLoading(false)
        })
    }
    // console.log(dropdownData, 'dropdownData')

  return (
    <>
        {(cpState?.getCPDashboardDetails?.loading || adminState?.getUserByToken?.loading || isLoading || authState?.createNewUser?.loading || masterState?.getDropdownData?.loading) &&
            <div>
                <Loader />
            </div>
        }
        <Grid className="main_grid_container">
            <GridItem colSpan={8}>
                <Box className='dashboard_box bg_shadow'>
                    <Heading as="h2" className='font-poppins page_heading'>Dashboard</Heading>
                    <Grid className="main_grid_container" gap={6} mt={8}>
                        <GridItem colSpan={6}>
                            <Box className='eligible_card cp_items_grid'>
                                <Heading mt={1} mb={3} as="h4">Borrowers Stats</Heading>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Borrower Registered</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.borrowers?.total_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Approved Borrower</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.borrowers?.approved_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Registration fee pending Borrower</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.borrowers?.fee_pending_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Borrowers Pending for Approval</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.borrowers?.pending_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Borrower Rejected</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.borrowers?.rejected_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Amount of loan Disbursed</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.borrowers?.loan_amount_disbursed}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Demand Live</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.borrowers?.loan_amount_demand}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Loan Funded Count</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.borrowers?.total_loans_funded}</Heading>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box className='eligible_card cp_items_grid'>
                                <Heading mt={1} mb={3} as="h4">Lenders Stats</Heading>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Numbers of Lenders Registered</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.lenders?.total_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Approved Lenders</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.lenders?.approved_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Registration fee pending Lenders</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.lenders?.fee_pending_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Lenders Pending for Approval</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.lenders?.pending_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Lenders Rejected</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.lenders?.rejected_count}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Balance In Wallet</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.lenders?.total_wallet_balance}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Invested Amount</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.lenders?.total_invested_amount}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Income/Payouts</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.lenders?.lender_payout}</Heading>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box className='eligible_card cp_items_grid'>
                                <Heading mt={1} mb={3} as="h4">Repayments</Heading>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>No. of EMIs Due</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.repayments?.nof_emi_due}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>No of Active Loan</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.repayments?.nof_active_loan}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>No of closed Loan</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.repayments?.nof_closed_loan}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Delayed Loans</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.repayments?.nof_delayed_loan}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Late Payment</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{dashboardData?.repayments?.nof_latepayemnt}</Heading>
                                </Box>
                            </Box>
                        </GridItem>
                    </Grid>
                </Box>
            </GridItem>
            <GridItem colSpan={4}>
                <NavBar data={userData} isAdmin isCP />
                <Box className='chart_box bg_shadow' mt={4}>
                    <Heading as="h2" className='font-poppins card_heading'>Invite Borrowers And Lenders</Heading>
                    <Box className='user_link_details' mt={4}>
                        <Heading as="h4" className='font-poppins'>Borrower Link</Heading>
                        <Flex alignItems='center' gap={2} className='link_box' mt={2}>
                            <Input
                                size="md"
                                className="input_box_link font-poppins"
                                value={dashboardData?.borrower_link ?? ''}
                                isReadOnly
                            />
                            <Spacer />
                            <IconButton onClick={() => handleCopy(dashboardData?.borrower_link, 'borrower_link')}
                                className={type === 'borrower_link' ? 'active_link_icon' : 'link_icon'}>
                                <CopyIcon />
                            </IconButton>
                        </Flex>
                    </Box>
                    <Box className='user_link_details' mt={4}>
                        <Heading as="h4" className='font-poppins'>Investor Link</Heading>
                        <Flex alignItems='center' gap={2} className='link_box' mt={2}>
                            <Input
                                size="md"
                                className="input_box_link font-poppins"
                                value={dashboardData?.lender_link ?? ''}
                                isReadOnly
                            />
                            <Spacer />
                            <IconButton onClick={() => handleCopy(dashboardData?.lender_link, 'investor_link')}
                                className={type === 'investor_link' ? 'active_link_icon' : 'link_icon'}>
                                <CopyIcon />
                            </IconButton>
                        </Flex>
                    </Box>
                </Box>
                <Box className='dashboard_box bg_shadow' mt={4}>
                    <Heading as="h2" className='font-poppins card_heading'>User Onboarding</Heading>
                    <Box className='form_kyc_cp'>
                        <Box mt={5}>
                            <Heading as="h1" className="font-poppins label_heading" mb={1}>Select User Type</Heading>
                            <Select
                                placeholder="Please Select User Type"
                                name="user_type"
                                variant="filled"
                                onChange={handleChange}
                                className="input_box_select font-poppins bg_white"
                                value={inputValues?.user_type ?? ''}
                            >
                                {userType?.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </Select>
                        </Box>
                        {inputValues?.user_type === '2' || inputValues?.user_type === '3' ?
                            <Box mt={4}>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>{inputValues?.user_type === '2' ? 'Select Borrower Type' : 'Select Investor Type'}</Heading>
                                <Select
                                    placeholder={inputValues?.user_type === '2' ? 'Please Select Borrower Type' : 'Please Select Investor Type'}
                                    name="employment_type"
                                    variant="filled"
                                    onChange={handleChange}
                                    className="input_box_select font-poppins bg_white"
                                    value={inputValues?.employment_type ?? ''}
                                >
                                    {inputValues?.user_type === '2' ?
                                        <>
                                            {dropdownData?.employment_type_borrower?.map((item) => (
                                                <option key={item.key} value={item.key}>{item.value}</option>
                                            ))}
                                        </> :
                                        <>
                                            {dropdownData?.employment_type_lender?.map((item) => (
                                                <option key={item.key} value={item.key}>{item.value}</option>
                                            ))}
                                        </>
                                    }
                                </Select>
                            </Box> : null
                        }
                        <Box mt={4}>
                            <Heading as="h1" className="font-poppins label_heading" mb={1}>Mobile Number</Heading>
                            <Input
                                type="number"
                                size="md"
                                name="mobile"
                                placeholder="Please Enter Mobile number"
                                onChange={handleChange}
                                className="input_box font-poppins bg_white"
                                value={inputValues?.mobile ?? ''}
                                onKeyDown={preventInvalidKey}
                            />
                        </Box>
                        <Box mt={4} className='d-flex justify-content-end'>
                            <CustomButton size="md" type="submit" title="Validate User" className="font-poppins btn_theme" onClick={validateUser} />
                        </Box>
                    </Box>
                </Box>
            </GridItem>
        </Grid>
        {/* <Box className='dashboard_box bg_shadow' mt={4}>
            <Heading as="h2" className='font-poppins page_heading'>User Onboarding</Heading>
            <Grid className="main_grid_container">
                <GridItem colSpan={6}>
                    <Box mt={5} className='form_kyc_cp'>
                        <Heading as="h1" className="font-poppins label_heading" mb={1}>Select User Type</Heading>
                        <Select
                            placeholder="Please Select User Type"
                            name="user_type"
                            variant="filled"
                            onChange={handleChange}
                            className="input_box_select font-poppins bg_white"
                            value={inputValues?.user_type ?? ''}
                        >
                            {userType?.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Select>
                    </Box>
                    {inputValues?.user_type === '2' || inputValues?.user_type === '3' ?
                        <Box mt={4} className='form_kyc_cp'>
                            <Heading as="h1" className="font-poppins label_heading" mb={1}>{inputValues?.user_type === '2' ? 'Select Borrower Type' : 'Select Investor Type'}</Heading>
                            <Select
                                placeholder={inputValues?.user_type === '2' ? 'Please Select Borrower Type' : 'Please Select Investor Type'}
                                name="employment_type"
                                variant="filled"
                                onChange={handleChange}
                                className="input_box_select font-poppins bg_white"
                                value={inputValues?.employment_type ?? ''}
                            >
                                {inputValues?.user_type === '2' ?
                                    <>
                                        {dropdownData?.employment_type_borrower?.map((item) => (
                                            <option key={item.key} value={item.key}>{item.value}</option>
                                        ))}
                                    </> :
                                    <>
                                        {dropdownData?.employment_type_lender?.map((item) => (
                                            <option key={item.key} value={item.key}>{item.value}</option>
                                        ))}
                                    </>
                                }
                            </Select>
                        </Box> : null
                    }
                    <Box mt={4} className='form_kyc_cp'>
                        <Heading as="h1" className="font-poppins label_heading" mb={1}>Mobile Number</Heading>
                        <Input
                            type="number"
                            size="md"
                            name="mobile"
                            placeholder="Please Enter Mobile number"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.mobile ?? ''}
                            onKeyDown={preventInvalidKey}
                        />
                    </Box>
                    <Box mt={4} className='d-flex justify-content-end'>
                        <CustomButton size="md" type="submit" title="Validate User" className="font-poppins btn_theme" onClick={validateUser} />
                    </Box>
                </GridItem>
            </Grid>
        </Box> */}
        {userFound?.form_completed_status === 0 &&
            <OnBoardKYC userFound={userFound} />
        }
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
})
  
export default connect(null, mapDispatchToProps)(CPDashboard)