import React from 'react'
import { Box, Heading, IconButton, Image, Text, Tooltip } from '@chakra-ui/react'
import * as routesNames from '../../../../../constants/routes'
import YesBankIcon from '../../../../../assets/images/yesbank.svg'
import { InfoOutlineIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import CustomButton from '../../../../elements/customButton'
import WithDrawIcon from '../../../../../assets/images/withdraw.svg'
import AddFundsIcon from '../../../../../assets/images/add-funds.svg'
import InvestmentChargesIcon from '../../../../../assets/images/investmentCharge.svg'
import RefundAmountIcon from '../../../../../assets/images/refundAmount.svg'
import MoneyWithdrawIcon from '../../../../../assets/images/recent-withdraw.svg'
import MoneyPledgedIcon from '../../../../../assets/images/recent-pledged.svg'
import EMIRecievedIcon from '../../../../../assets/images/emi.svg'
import MoneyAddIcon from '../../../../../assets/images/MoneyAdd.svg'

export default function WalletBalance(props) {

    const data = props?.walletData

 return (
    <Box className='chart_box bg_shadow' mt={4}>
        {(data?.payment_mode === 0 || data?.payment_mode === 1) ?
            <Heading as="h2" className='font-poppins card_heading'>Wallet balance</Heading> :
            <Box className='wallet_card_heading d-flex align-items-center justify-content-between'>
                <Heading as="h3" className='font-poppins'>Would you like to reinvest money?</Heading>
                <Box className='wallet_selection_action d-flex align-items-center col-gap-10'>
                    <Tooltip label="Helps you to gain compounded interest"
                        hasArrow fontSize='xs' placement='bottom-start'>
                        <InfoOutlineIcon />
                    </Tooltip>
                    <CustomButton size="md" type="submit" title="Yes" className="btn_confirm font-poppins" onClick={() => props.handleChangePaymentMode('yes')}
                        isDisabled={!props.isKYCCompleted}
                    />
                    <CustomButton size="md" type="submit" title="No" className="btn_canceled font-poppins" onClick={() => props.handleChangePaymentMode('no')}
                        isDisabled={!props.isKYCCompleted}
                    />
                </Box>
            </Box>
        }
        <Box mt={2} className='alpha_score bg_shadow' pt={1}>
            {data?.payment_mode === 0 ?
                <Box className='payment_mode_text'>
                    <Text className='font-poppins'>You will receive your repayment in your Escrow Account</Text>
                </Box> :
            data?.payment_mode === 1 ?
                <Box className='payment_mode_text'>
                    <Text className='font-poppins'>You will receive your repayment in your Bank Account</Text>
                </Box> :
                <Box className='d-flex align-items-center justify-content-between wallet_flex'>
                    <Heading as="h2" className='font-poppins'>Wallet Balance</Heading>
                    <Heading as="p" className='font-poppins'>Powered By<Image src={YesBankIcon} /></Heading>
                </Box>
            }
            <Box className='price_bg'>
                <Box className='price_box d-flex align-items-center col-gap-10'>
                    <Heading as="h2" className='font-poppins'>₹ {props.viewAmount ? (data?.effective_balance ? data?.effective_balance : '0'):'******'}</Heading>
                    <IconButton onClick={props.handleViewAmount}>
                       {!props.viewAmount ? <ViewIcon /> : <ViewOffIcon />}
                    </IconButton>
                </Box>
                <Box className='price_actions'>
                    <Text mb={2} className='font-poppins'>Available Balance To Withdraw ₹ {props.viewAmount ? (data?.effective_balance ? data?.effective_balance : '0'):'******'}</Text>
                    <Box className='d-flex align-items-center justify-content-between'>
                        <CustomButton size="md" type="submit" title="WITHDRAW" className="btn_white_solid font-poppins"
                            leftIcon={<Image src={WithDrawIcon} />} onClick={props.handleWithdrawFundsDialog}
                            isDisabled={!props.isKYCCompleted}
                        />
                        <CustomButton size="md" type="submit" title="ADD FUNDS" className="btn_white_solid font-poppins"
                            leftIcon={<Image src={AddFundsIcon} />} onClick={props.handleAddFundsDialog}
                            isDisabled={!props.isKYCCompleted}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
        {props.location === routesNames.INVESTERPORTFOLIO ?
            <Box mt={3} className='recent_transaction'>
                <Heading as="h2" className='font-poppins'>Your Recent Transaction</Heading>
                {data?.recent_wallet_transactions?.map((item, index) => {
                    return (
                        <Box mt={1} className='recent_transaction_card' key={index}>
                            <Box className='recent_transaction_text d-flex align-items-center col-gap-10'>
                                <Image src={item.transaction_type  === 'investment_charge' ? InvestmentChargesIcon :
                                    item.transaction_type  === 'refund_pledged_amount_to_wallet' ? RefundAmountIcon :
                                    item.transaction_type  === 'pledge_loan_amount' ? MoneyPledgedIcon : 
                                    item.transaction_type  === 'wallet_withdraw' ? MoneyWithdrawIcon : 
                                    item.transaction_type  === 'emi_paid_in_bank_account' ? EMIRecievedIcon : 
                                    MoneyAddIcon}
                                />
                                <Heading as="h4" className='font-poppins'>{item.transaction_type.replaceAll('_', ' ')}</Heading>
                            </Box>
                            <Text className='font-poppins'>₹{item.amount}</Text>
                        </Box>
                    )
                })}
            </Box> : null
        }
    </Box>
  )
}
