import React, { useEffect, useRef, useState } from 'react'
import { Box, Heading, Grid, GridItem, Image } from "@chakra-ui/react"
import NavBar from "../../../elements/NavBar"
import { useHistory } from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { getItem, removeAll } from '../../../../utilities/authUtils'
import InvestmentFilters from '../components/InvestmentFilters'
import ManualInvestCard from './components/ManualInvestCard'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import CustomButton from '../../../elements/customButton'
import ManualInvestTable from './components/ManualInvestTable'
import InvestNowSidebar from './components/InvestNowSidebar'
import Loader from '../../../elements/loader'
import AddFundsDialog from '../Wallet/Components/AddFundsDialog'
import PaymentDialogInvestor from '../../../modules/PaymentModule/PaymentDialogInvestor'
import CustomToast from '../../../elements/customToast'
import options from '../../../../assets/JsonData/investFilterData.json'
import NoData from '../../../elements/NoData'
import ImportantDialog from '../../../elements/ImportantDialog'
import CalculatorIcon from '../../../../assets/images/calculator.svg'
import InvestorEmiCalculatorSidebar from './components/InvestorEmiCalculatorSidebar'
import { uploadNetworthCertificate } from '../../../../services/masterServices'

const tabNames = [
    {
        id: 1,
        name: 'Card View',
    },
    {
        id: 2,
        name: 'Table View',
    },
  ]

const amountData = [
    {
      id: 1,
      name: '10,00,000',
    },
    {
      id: 2,
      name: '100,000',
    },
    {
      id: 3,
      name: '75,000',
    },
    {
      id: 4,
      name: '50,000',
    },
    {
        id: 5,
        name: '25,000',
    },
    {
        id: 6,
        name: '10,000',
    },
  ]

const ManualInvestment = (props) => {

    const MAXAMOUNT = 5000000
    const history = useHistory()
    const certificateRef = useRef()
    const { addToast } = CustomToast()
    const investorState = useSelector(state => state.investorState)
    const masterState = useSelector((state) => state.masterState)
    const borrowerState = useSelector((state) => state.borrowerState)
    const [inputValues, setInputValues] = useState({})
    const [filterData, setFilterData] = useState([])
    const [openCalculatorSidebar, setOpenCalculatorSidebar] = useState(false)
    const [openSorting, setOpenSorting] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [openInvestSidebar, setOpenInvestSidebar] = useState(false)
    const [sortingData, setSortingData] = useState('')
    const [declaredCheck, setDeclaredCheck] = useState(false)
    const [view, setView] = useState(1)
    const [currentPage, setCurrentPage] = useState(0);
    const [amount, setAmount] = useState(null)
    const [openAddFunds, setOpenAddFunds] = useState(false)
    const [openPaymentModal, setOpenPaymentModal] = useState(false)
    const [payableAmount, setPayableAmount] = useState(null)
    const [sidebarData, setSidebarData] = useState(null)
    const [filterManualList, setFilterManualList] = useState([])
    const [certificateData, setCertificateData] = useState(null)
    const [acknowledgeConsent, setAcknowledgeConsent] = useState(false)
    const [certificateConsent, setCertificateConsent] = useState(false)
    const [sortOrder, setSortOrder] = useState('desc')
    const [isLoading, setIsLoading] = useState(false)

    const userData = masterState?.getCurrentUserDetails?.data?.data
    const walletData = investorState?.getInvestorWalletData?.data?.data
    const manualInvestData = investorState?.getInvestorOpportunityData?.data?.data
    const creditData = borrowerState?.getBorrowerCreditData?.data?.data
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(manualInvestData?.length / PER_PAGE);
    const isKycCompleted = userData?.[0]?.account_approval_status === 1

    useEffect(()=>{
     props.actions.getInvestorOpportunitiesAction()
     props.actions.getCurrentUserDetailsAction()
     props.actions.getInvestmentWalletAction()
    },[])

    useEffect(() => {
        setFilterManualList(investorState?.getInvestorOpportunityData?.data?.data)
    }, [investorState?.getInvestorOpportunityData])

    useEffect(() => {
        if(investorState?.getInvestInLoanDetails?.data?.success === true) {
          addToast({ message: investorState?.getInvestInLoanDetails?.data?.message, status: 'success' })
          setOpenInvestSidebar(false)
          setSidebarData(null)
          setInputValues({})
          setDeclaredCheck(false)
          props.actions.getInvestorOpportunitiesAction()
          props.actions.getInvestmentWalletAction()
        } else if(investorState?.getInvestInLoanDetails?.data?.success === false) {
          addToast({ message: investorState?.getInvestInLoanDetails?.data?.message, status: 'error' })
        }
    }, [investorState?.getInvestInLoanDetails])

    useEffect(() => {
        return () => {
          props?.actions?.cleanUpToast()
        }
    }, [])

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    const handleDropdown = (type) => {
        if (type === 'sort') {
            setOpenSorting(!openSorting)
            setOpenFilter(false)
        } else if (type === 'filter') {
            setOpenFilter(!openFilter)
            setOpenSorting(false)
        } else {
            setOpenFilter(!openFilter)
            setOpenSorting(!openSorting)
        }
    }

    const handleSearchName = (e) => {
        const {value} = e.target
        if (value !== '') {
          const result = manualInvestData?.filter((item) => {
            return item?.loan_number?.toLowerCase().includes(value.toLowerCase()) || item?.alpha_score?.toLowerCase().includes(value.toLowerCase())
          })
          setFilterManualList(result)
        } else {
            setFilterManualList(manualInvestData)
        }
      }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value })
    }

    const handleCalculatorSidebar = () => {
        setOpenCalculatorSidebar(!openCalculatorSidebar)
    }

    const handleCheck = (e, type) => {
        if (type === 'acknowledge') {
            setAcknowledgeConsent(e.target.checked)
        }
        if (type === 'certificate') {
            setCertificateConsent(e.target.checked)
        }
        if (type === 'agree_consent') {
            setDeclaredCheck(e.target.checked)
        }
    }

    const handleOpenInvestSidebar = (item) => {
        setOpenInvestSidebar(true)
        setSidebarData(item)
    }

    const handleCloseInvestSidebar = () => {
        setOpenInvestSidebar(false)
        setSidebarData(null)
        setInputValues({})
        setDeclaredCheck(false)
    }

    const handleSelectSorting = (item) => {
        setSortingData(item)
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        let sortedData = [...filterManualList].sort((a, b) => {
            let valueA, valueB;
            if (item === 'Amount') {
                valueA = a.loan_amount || 0;
                valueB = b.loan_amount || 0;
            } else if (item === 'Tenure') {
                valueA = a.tenure || 0;
                valueB = b.tenure || 0;
            } else if (item === 'Risk Rating') {
              valueA = a.risk_category || 0;
              valueB = b.risk_category || 0;
            } else if (item === '% Funded') {
                valueA = a.committed_loan_amount || 0;
                valueB = b.committed_loan_amount || 0;
            } else if (item === 'Loan Type') {
              valueA = a.product_id || 0;
              valueB = b.product_id || 0;
          }
            // Apply sorting order
            if (sortOrder === 'asc') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });
        setFilterManualList(sortedData)
    }

    const handleSelectFilter = (e, val) => {
        if (e.target.checked) {
            setFilterData([...filterData, val]);
        } else {
            setFilterData(filterData.filter((item) => item.id !== val.id));
        }
    }

    const handleApplyFilters = () => {
        const selectedLoanTypes = filterData.filter((filter) => options.filterOptions.loanType.some((type) => type.id === filter.id))
        const selectedRiskCategories = filterData.filter((filter) => options.filterOptions.riskType.some((type) => type.id === filter.id))
      
        let updatedList = manualInvestData.filter((loan) => {
          const loanTypeMatch = selectedLoanTypes?.length === 0 || selectedLoanTypes?.some((filter) => loan?.product_id === filter?.id)
          const riskCategoryMatch = selectedRiskCategories?.length === 0 || selectedRiskCategories?.some((filter) => loan?.risk_category === filter?.name)
          let daysMatch = true
          if (inputValues?.days) {
            daysMatch = loan?.tenure === parseInt(inputValues?.days)
          }
          let monthsMatch = true
          if (inputValues?.months) {
            monthsMatch = loan?.tenure === parseInt(inputValues?.months)
          }
          return loanTypeMatch && riskCategoryMatch && daysMatch && monthsMatch
        })
        setFilterManualList(updatedList)
      }

    const showOpenFileDialog = () => {
        certificateRef.current.click()
    }
    
    const onChangeFile = (e) => {
        setCertificateData(e.target.files[0])
    }

    const handleSaveDocumnent = () => {
        if (!certificateData) {
          addToast({ message: 'Please Upload Certificate', status: 'error' })
          return
        }
        const formData = new FormData()
        formData.append('networth_certificate', certificateData)
        setIsLoading(true)
        uploadNetworthCertificate(formData).then((res) => {
          setIsLoading(false)
          if (res?.data?.success === true) {
            addToast({message: 'Document Uploaded Successfully', status: 'success'})
            props.actions.getCurrentUserDetailsAction()
          } else if (res?.data?.success === false) {
            addToast({message: res?.data?.message, status: 'error'})
          }
        }).catch((error) => {
          addToast({message: error, status: 'error'})
          setIsLoading(false)
        })
      }
    
    const handleAddFundsDialog = () => {
        setOpenAddFunds(!openAddFunds)
    }

    const handleSelectAmount = (item) => {
        const selectedAmount = amountData?.find((elem) => elem.id === item.id)
        setAmount(selectedAmount)
        setInputValues({ ...inputValues, lending_amount: selectedAmount.name });
    }

    const handleOpenPaymentDialog = (amount) => {
        if (!inputValues?.lending_amount || inputValues?.lending_amount <= 99) {
            addToast({message: 'Minimum Amount is 100', status: 'error'})
            return
        }
        if (+(inputValues?.lending_amount) > MAXAMOUNT) {
            addToast({message: 'Can not Add Funds more than 50 Lakhs', status: 'error'})
            return
        }
        setPayableAmount(amount)
        setOpenPaymentModal(true)
    }

    const handleClosePaymentDialog = () => {
        setPayableAmount(null)
        setOpenPaymentModal(false)
    }

    const handleViewHistoryCalender = (borrowerId) => {
        // props.actions.getBorrowerCreditDataAction({id: '1175'})
        props.actions.getBorrowerCreditDataAction({id: borrowerId})
    }

    const handleInvestNow = (data) => {
        const amount = data?.amount;
        const isMultipleOfThousand = amount % 1000 === 0;
        if (data?.amount < 1000 || !data?.amount) {
            addToast({ message: 'The Minimum Investment Amount is ₹1000', status: 'error' })
            return
        }
        if (!isMultipleOfThousand) {
            addToast({ message: 'The Investment Amount Should be multiple of ₹1000', status: 'error' })
            return
        }
        if (!declaredCheck) {
            addToast({ message: 'Please accept acknowledgement', status: 'error' })
            return
        }
        if (+(sidebarData?.remaining_loan_amount) < +(data?.amount)) {
            addToast({ message: 'The Investment Amount should be less than Remaining Amount', status: 'error' })
            return
        }
        props.actions.investInLoanAction(data)
    }

    const handleReset = () => {
        setFilterData([])
        setInputValues({})
        setFilterManualList(manualInvestData)
    }

    const handleClose = (type) => {
        if (type === 'sort') {
            setOpenSorting(false)

        }
        if (type === 'filter') {
            setOpenFilter(false)
        }
    }

    const handleView = (item) => {
        setView(item)
    }

    const handleProfile = () => {
        history.push(routesNames.INVESTORPROFILE)
    }
    
    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }
    return (
        <Box>
            {(investorState?.getInvestorOpportunityData?.loading || masterState?.getCurrentUserDetails?.loading ||
                investorState?.getInvestorWalletData?.loading || investorState?.getInvestInLoanDetails?.loading || isLoading ||
                borrowerState?.getBorrowerCreditData?.loading) &&
                <div>
                <Loader />
                </div>
            }
            <Box className='dashboard_box bg_shadow'>
                <Grid className="main_grid_container align-items-center">
                    <GridItem colSpan={8}>
                        <Box className='d-flex align-items-center justify-content-between'>
                            <Heading as="h2" className='font-poppins page_heading'>Manual Investment</Heading>
                            <ImportantDialog data={userData?.[0]?.usertype} />
                        </Box>
                    </GridItem>
                    <GridItem colSpan={4}>
                        <NavBar data={userData} handleProfile={handleProfile} />
                    </GridItem>
                </Grid>
                <Grid className="main_grid_container">
                    <GridItem colSpan={12}>
                        <Box mt={5} className='d-flex align-items-center justify-content-end col-gap-15'>
                            <InvestmentFilters sortOptions={options.sortOptions} filterOptions={options.filterOptions} filterData={filterData}
                                handleDropdown={handleDropdown} handleClose={handleClose} handleSelectFilter={handleSelectFilter}
                                handleReset={handleReset} openFilter={openFilter} openSorting={openSorting} sortingData={sortingData}
                                handleSelectSorting={handleSelectSorting} handleSearchName={handleSearchName} handleChange={handleChange}
                                inputValues={inputValues} sortOrder={sortOrder} handleApplyFilters={handleApplyFilters}
                            />
                            <Box className='d-flex align-items-center justify-content-end col-gap-10 view_box'>
                                {tabNames.map((item, index) => {
                                    return (
                                        <CustomButton key={index} className={item.id === view ? "font-poppins btn_view_active btn_view" : "font-poppins btn_view"}
                                            title={item.name} onClick={() => handleView(item.id)}
                                        />
                                    )
                                })}
                            </Box>
                            <CustomButton size="md" type="submit" title="Return Calculator" className="btn_table_action font-poppins" rightIcon={<Image src={CalculatorIcon} />} onClick={handleCalculatorSidebar} />
                        </Box>
                        {filterManualList?.length > 0 ? (
                                <>
                                    {view === 1 ? (
                                        <ManualInvestCard 
                                            manualInvestData={filterManualList} 
                                            handleOpenInvestSidebar={handleOpenInvestSidebar}
                                            handleViewHistoryCalender={handleViewHistoryCalender} 
                                            creditData={creditData}
                                            isKycCompleted={isKycCompleted}
                                        />
                                    ) : (
                                        <ManualInvestTable 
                                            handleOpenInvestSidebar={handleOpenInvestSidebar} 
                                            offset={offset} 
                                            pageCount={pageCount}
                                            PER_PAGE={PER_PAGE} 
                                            handlePageClick={handlePageClick} 
                                            manualInvestData={filterManualList}
                                            isKycCompleted={isKycCompleted}
                                        />
                                    )}
                                </>
                            ) : (
                                <NoData title="No Record Found" />
                            )
                        }
                    </GridItem>
                </Grid>
            </Box>
            <InvestNowSidebar walletData={walletData} openSidebar={openInvestSidebar} handleChange={handleChange} inputValues={inputValues}
                handleOpenSidebar={handleOpenInvestSidebar} handleCloseSidebar={handleCloseInvestSidebar}
                declaredCheck={declaredCheck} handleCheck={handleCheck} handleAddFundsDialog={handleAddFundsDialog}
                sidebarData={sidebarData} handleInvestNow={handleInvestNow}
            />
            <AddFundsDialog data={amountData} openAddFunds={openAddFunds} handleAddFundsDialog={handleAddFundsDialog}
                handleSelectAmount={handleSelectAmount} amount={amount} handleOpenPaymentDialog={handleOpenPaymentDialog}
                handleChange={handleChange} inputValues={inputValues} certificateRef={certificateRef} onChangeFile={onChangeFile}
                showOpenFileDialog={showOpenFileDialog} certificateData={certificateData} acknowledgeConsent={acknowledgeConsent}
                certificateConsent={certificateConsent} handleCheck={handleCheck} userData={userData}
                handleSaveDocumnent={handleSaveDocumnent}
            />
            <PaymentDialogInvestor title="Add Funds" openPayModal={openPaymentModal} payableAmount={payableAmount}
                handleClosePaymentDialog={handleClosePaymentDialog} userData={userData} handleAddFundsDialog={handleAddFundsDialog}
            />
            <InvestorEmiCalculatorSidebar handleCalculatorSidebar={handleCalculatorSidebar} openCalculatorSidebar={openCalculatorSidebar} />
        </Box>
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
  export default connect(null, mapDispatchToProps)(ManualInvestment)