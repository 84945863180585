import React, { useEffect, useState } from 'react'
import {Box, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import Pagination from '../../../../elements/Pagination';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../../../../redux/actions';
import { connect, useSelector } from 'react-redux';
import Loader from '../../../../elements/loader';
import moment from 'moment';
import NoData from '../../../../elements/NoData';

const ApprovedBorrowers = (props) => {

    const adminState = useSelector((state) => state.adminState)
    const [currentPage, setCurrentPage] = useState(0)
    const [filterData, setFilterData] = useState([])
    const approvedBorrowers = adminState?.getNewAccount?.data?.data
    const PER_PAGE = 15;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(filterData?.length / PER_PAGE);

    useEffect(() => {
        if (props.tabSelected === 'Approved Borrowers') {
            let data = {
                status: 'approved',
                usertype: 2,
            }
            props?.actions?.getNewAccountAction(data)
        }
    }, [props.tabSelected])

    useEffect(() => {
        if (adminState?.getNewAccount?.data?.success === true) {
          setFilterData(approvedBorrowers)
        }
    }, [adminState?.getNewAccount])

    const handleSearch = (e) => {
        const {value} = e.target
        if (value !== '') {
          const result = approvedBorrowers.filter((item) => {
            return item?.escrow_account_id?.toLowerCase().includes(value.toLowerCase()) || item?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.first_name?.toLowerCase().includes(value.toLowerCase())
          })
          setFilterData(result)
        } else {
          setFilterData(approvedBorrowers)
        }
    }
  
    const handlePageClick = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    }

  return (
    <>
        {(adminState?.getNewAccount?.loading) &&
            <div>
                <Loader />
            </div>
        }
        <Box mt={1}>
            <Box className='search_box'>
                <Input type="text" size="md" name="search" placeholder="Search" onChange={handleSearch}
                    className="input_box font-poppins"
                />
            </Box>
            <>
                {filterData?.length > 0 ?
                    <Box mt={2} className='custom_table_wrapper'>
                        <TableContainer>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>S.No.</Th>
                                        <Th>Name</Th>
                                        <Th>Escrow Id</Th>
                                        <Th>Registration Date</Th>
                                        <Th>Phone Number</Th>
                                        <Th>Email Id</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {filterData?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                                        const serialNumber = offset + index + 1;
                                        return (
                                            <Tr key={index}>
                                                <Td>
                                                    <Text className="font-poppins table_text">{serialNumber}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item?.first_name}{' '}{item?.last_name}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item?.escrow_account_id ? item?.escrow_account_id : 'Not Assigned'}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item?.created_at ? moment(item?.created_at).format('DD-MMM-YYYY'): 'Not Available'}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item?.mobile}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item?.email}</Text>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
                    </Box> :
                    <NoData title="No Record Found" />
                }
            </>
        </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(ApprovedBorrowers)