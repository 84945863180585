/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import AppRoutes from './Routes'
import { ActionCreators } from '../../../redux/actions'
import { getVersion } from '../../../services/masterServices'
import CustomToast from '../../elements/customToast'
import Loader from '../../elements/loader'
import { setItem } from '../../../utilities/authUtils'

const App = (props) => {

  const {addToast} = CustomToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isCastlerActive, setIsCastlerActive] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    getVersion().then((res) => {
      setIsLoading(false)
      if (res?.data?.success === true && res?.data?.status === true) {
        const newIsCastlerActive = res?.data?.data?.is_castler_active
        setItem('castlerActive', newIsCastlerActive)
        if (newIsCastlerActive !== isCastlerActive) {
          setIsCastlerActive(newIsCastlerActive)
        }
      }
    }).catch((error) => {
      addToast({message: 'Something Went Wrong', status: 'error'})
      setIsLoading(false)
    })
  }, [isCastlerActive])

  return (
    <>
      {(isLoading) &&
        <div>
          <Loader />
        </div>
      }
      <div className="App">
        <AppRoutes />
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(App)
