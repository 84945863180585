import React, { useState } from 'react'
import {Box, GridItem, Heading, IconButton, Image, Input, Text} from '@chakra-ui/react'
import ApproveIcon from '../../../../../assets/images/approve.svg'
import RejectIcon from '../../../../../assets/images/reject.svg'
import ApproveWhiteIcon from '../../../../../assets/images/approve-white.svg'
import RejectWhiteIcon from '../../../../../assets/images/reject-white.svg'
import DigioVerifyIcon from '../../../../../assets/images/digioApprove.svg'

export default function InvestorDocumentCard({item, index, inputValues, handleSelectDocument, handleChecked, handleChange, type, userData}) {


    const [isChecked, setIsChecked] = useState(null)
    return (
        <GridItem key={index} colSpan={6}>
            <Box className={`${type === item?.upload_field_name ? 'verify_card_details_active' : 'verify_card_details'} ${isChecked === 'approve' ? 'approved' : isChecked === 'reject' ? 'rejected' : ''}`}>
                <Box className='d-flex align-items-center justify-content-between document_header'>
                    <Box className='document_name'>
                        <Box className='document_status' />
                        <Heading as="h4" className='font-poppins'>{item?.title}</Heading>
                    </Box>
                    <Box className='d-flex align-items-center col-gap-15 document_details_action'>
                        <IconButton className={isChecked === 'approve' ? 
                            'approve_details_btn_active' : 'approve_details_btn'} 
                            onClick={() => {
                                setIsChecked('approve')
                                handleChecked(item?.upload_field_name, 'approve')}
                            }
                            >
                            <Image src={isChecked === 'approve' ? ApproveWhiteIcon : ApproveIcon} />
                        </IconButton>
                        <IconButton className={isChecked === 'reject' ?
                            'reject_details_btn_active' : 'reject_details_btn'} 
                            onClick={() => {
                                setIsChecked('reject')
                                handleChecked(item?.upload_field_name, 'reject')}
                            }
                            >
                            <Image src={isChecked === 'reject' ? RejectWhiteIcon : RejectIcon} />
                        </IconButton>
                    </Box>
                </Box>
                {item.title === 'PAN Card' ?
                    <Box className='verify_document_details'>
                        <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                            <Box className='d-flex align-items-center col-gap-10 flex-wrap'>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? 'POI:' : 'PAN Number:'}</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.pancard_data?.pan}</Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? 'DOI:' : 'DOB:'}</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.dob}</Heading>
                                </Box>
                            </Box>
                            <Box className='verify_document_data' mt={3}>
                                <Text className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? 'Incorporation Name:' : 'Name'}</Text>
                                <Heading as="h6" className='font-poppins'>{userData?.data?.first_name}{' '}{userData?.data?.middle_name}{' '}{userData?.data?.last_name}</Heading>
                            </Box>
                            <Box className='verify_document_data' mt={3}>
                                {/* <Text className='font-poppins'>Father’s Name:</Text>
                                <Heading as="h6" className='font-poppins'>{userData?.data?.personal_data?.father_name}</Heading> */}
                                <Text className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? 'Authorized Name:' : 'Father’s Name:'}</Text>
                                <Heading as="h6" className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? userData?.data?.legal_name : userData?.data?.personal_data?.father_name}</Heading>
                            </Box>
                        </Box>
                        <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="pan_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.pan_comment ?? ''}
                            />
                        </Box>
                    </Box> :
                item.title === 'Aadhaar Card' ?
                    <Box className='verify_document_details'>
                        <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                            <Box className='d-flex align-items-center col-gap-10 flex-wrap'>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? 'Authorizer Aadhaar:' : 'Aadhaar Number:'}</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.aadhar_card_data?.aadhar_number}</Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    {/* <Text className='font-poppins'>DOB:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.dob}</Heading> */}
                                    <Text className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? 'Authorizer DOB:' : 'DOB:'}</Text>
                                    <Heading as="h6" className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? userData?.data?.aadhar_card_data?.dob : userData?.data?.dob}</Heading>
                                </Box>
                            </Box>
                            <Box className='d-flex align-items-center col-gap-10 flex-wrap' mt={3}>
                                <Box className='verify_document_data width-48'>
                                    {/* <Text className='font-poppins'>Name:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.first_name}{' '}{userData?.data?.middle_name}{' '}{userData?.data?.last_name}</Heading> */}
                                    <Text className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? 'Authorizer Name:' : 'Name:'}</Text>
                                <   Heading as="h6" className='font-poppins'>{(userData?.data?.employment_type === '4' || userData?.data?.employment_type === '5') ? userData?.data?.legal_name : userData?.data?.first_name}{' '}{userData?.data?.middle_name}{' '}{userData?.data?.last_name}</Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Father’s Name:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.personal_data?.father_name}</Heading>
                                </Box>
                            </Box>
                            <Box className='d-flex align-items-center col-gap-10 flex-wrap' mt={3}>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Address:</Text>
                                    <Heading as="h6" className='font-poppins'>
                                        {userData?.data?.personal_data?.address_houseNumber}
                                        {userData?.data?.personal_data?.address_landmark}
                                        {userData?.data?.personal_data?.address_street}{' '}
                                        {userData?.data?.personal_data?.address_subdistrict}{', '}
                                        {userData?.data?.personal_data?.address_district}{', '}
                                        {userData?.data?.personal_data?.address_state}{', '}
                                        {userData?.data?.personal_data?.address_pincode}
                                        {userData?.data?.personal_data?.address_country}
                                    </Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Image src={userData?.data?.digilocker_request?.status === 'approved' ? DigioVerifyIcon : ''} />
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="aadhar_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.aadhar_comment ?? ''}
                            />
                        </Box>
                    </Box> :
                item.title === 'Bank Statement' ?
                    <Box className='verify_document_details'>
                        <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                            <Box className='d-flex align-items-center col-gap-10 flex-wrap'>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Bank Name:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.bank_data?.bank_name}</Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Bank IFSC:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.bank_data?.ifsc_code}</Heading>
                                </Box>
                            </Box>
                            <Box className='verify_document_data' mt={3}>
                                <Text className='font-poppins'>Account Number:</Text>
                                <Heading as="h6" className='font-poppins'>{userData?.data?.bank_data?.account_number}</Heading>
                            </Box>
                        </Box>
                        <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="bank_statement_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.bank_statement_comment ?? ''}
                            />
                        </Box>
                    </Box> :
                item.title =="GST Certificate" ?
                    <Box className='verify_document_details'>
                        <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                            <Box className='d-flex align-items-center col-gap-10'>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>GST Number</Text>
                                    <Heading as="h6" className='font-poppins'>{ userData?.data?.personal_data?.manual_gst_number}</Heading>
                                </Box>
                                        {
                                (userData?.data?.employment_type ==5 || userData?.data?.employment_type ==4 || userData?.data?.employment_type ==7) &&
                                <Box className='verify_document_data width-48'>
                                <Text className='font-poppins'> Registered Address</Text>
                                <Heading as="h6" className='font-poppins'>{userData?.data?.personal_data?.company_reg_address}</Heading>
                            </Box>
                                        }
                               
                            </Box>
                            {
                                (userData?.data?.employment_type ==5 || userData?.data?.employment_type ==4 || userData?.data?.employment_type ==7) &&
                            <Box className='d-flex align-items-center col-gap-10' mt={3}>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Pincode </Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.personal_data?.company_reg_pincode}</Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'> State</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.personal_data?.company_reg_state}</Heading>
                                </Box>
                            </Box>
                            }
                                  <Box className='verify_document_details' ml={-4}>
                                  <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                                      <Box className='verify_document_data'>
                                          <Heading as="h6" className='font-poppins'>Is this Document Valid?</Heading>
                                      </Box>
                                  </Box>
                                  <Box mt={6} className='comment_box'>
                                      <Input type="text" size="md" name={`${item?.upload_field_name}_comment`} placeholder="Comment (Optional)"
                                          className="font-poppins" onChange={handleChange} value={inputValues[`${item?.upload_field_name}_comment`] ?? ''}
                                      />
                                  </Box>
                              </Box>
                        </Box>
                    </Box>:
                    item.title =="Registration Certificate" ?
                        <Box className='verify_document_details'>
                             <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                                      <Box className='verify_document_data'>
                                          <Heading as="h6" className='font-poppins'>Is this Document Valid?</Heading>
                                      </Box>
                                  </Box>
                                  <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="reg_act_certificate_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.reg_act_certificate_comment ?? ''}
                            />
                        </Box>
                        </Box>:
                item.title =="Board resolution" ?        
                    <Box className='verify_document_details'>
                        <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                            <Box className='d-flex align-items-center col-gap-10'>
                                <Box className='verify_document_data'>
                                    <Text className='font-poppins'> Business Address</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.personal_data?.is_business_address_same==1 ? userData?.data?.personal_data?.company_reg_address :userData?.data?.personal_data?.company_buss_address}</Heading>
                                </Box>
                            </Box>
                            <Box className='d-flex align-items-center col-gap-10' mt={3}>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Pincode </Text>
                                    <Heading as="h6" className='font-poppins'>{ userData?.data?.personal_data?.is_business_address_same==1 ? userData?.data?.personal_data?.company_reg_pincode: userData?.data?.personal_data?.company_buss_pincode}</Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'> State</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.personal_data?.is_business_address_same==1 ? userData?.data?.personal_data?.company_reg_state :userData?.data?.personal_data?.company_buss_state}</Heading>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="board_resolution_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.board_resolution_comment ?? ''}
                            />
                        </Box>
                    </Box> :
                     item.title =="Certification of Incorporation" ? 
                    <Box className='verify_document_details'>
                                  <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                                      <Box className='verify_document_data'>
                                          <Heading as="h6" className='font-poppins'>Is this Document Valid?</Heading>
                                      </Box>
                                  </Box>
                                  {/* <Box mt={6} className='comment_box'>
                                      <Input type="text" size="md" name={`${item?.upload_field_name}_comment`} placeholder="Comment (Optional)"
                                          className="font-poppins" onChange={handleChange} value={inputValues[`${item?.upload_field_name}_comment`] ?? ''}
                                      />
                                  </Box> */}
                                     <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="incorp_certificate_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.incorp_certificate_comment ?? ''}
                            />
                        </Box>
                              </Box>:
                               item.title =="Memorandum and Articles of Association" ? 
                              <Box className='verify_document_details'>
                                            <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                                                <Box className='verify_document_data'>
                                                    <Heading as="h6" className='font-poppins'>Is this Document Valid?</Heading>
                                                </Box>
                                            </Box>
                                               <Box mt={6} className='comment_box'>
                                      <Input type="text" size="md" name="mem_art_association_comment" placeholder="Comment (Optional)"
                                          className="font-poppins" onChange={handleChange} value={inputValues?.mem_art_association_comment ?? ''}
                                      />
                                  </Box>
                                        </Box>:
                                         item.title =="RBI Licence copy" ? 
                                         <Box className='verify_document_details'>
                                                       <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                                                           <Box className='verify_document_data'>
                                                               <Heading as="h6" className='font-poppins'>Is this Document Valid?</Heading>
                                                           </Box>
                                                       </Box>
                                                          <Box mt={6} className='comment_box'>
                                                 <Input type="text" size="md" name="rbi_licence_copy_comment" placeholder="Comment (Optional)"
                                                     className="font-poppins" onChange={handleChange} value={inputValues?.rbi_licence_copy_comment ?? ''}
                                                 />
                                             </Box>
                                                   </Box>:
                               item.title =="Certification of commencement of the business" ? 
                              <Box className='verify_document_details'>
                                            <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                                                <Box className='verify_document_data'>
                                                    <Heading as="h6" className='font-poppins'>Is this Document Valid?</Heading>
                                                </Box>
                                            </Box>
                                               <Box mt={6} className='comment_box'>
                                      <Input type="text" size="md" name="mem_art_association_comment" placeholder="Comment (Optional)"
                                          className="font-poppins" onChange={handleChange} value={inputValues?.cob_certificate_comment ?? ''}
                                      />
                                  </Box>
                                        </Box>:
                              
                     item.title =="Partnership Deed" ? 
                    <Box className='verify_document_details'>
                                  <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                                      <Box className='verify_document_data'>
                                          <Heading as="h6" className='font-poppins'>Is this Document Valid?</Heading>
                                      </Box>
                                  </Box>
                                     <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="partnership_deed_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.partnership_deed_comment ?? ''}
                            />
                        </Box>
                              </Box>
                              :
                        item.title =="Certification of Udyam Portal" ?
                        <Box className='verify_document_details'>
                             <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                                      <Box className='verify_document_data'>
                                          <Heading as="h6" className='font-poppins'>Is tddhis Document Valid?</Heading>
                                      </Box>
                                  </Box>
                                  <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="udyam_certificate_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.udyam_certificate_comment ?? ''}
                            />
                        </Box>
                        </Box>
                    
                              : ""
                             
                }
            </Box>
        </GridItem>
    )
}
