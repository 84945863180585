import axios from "axios"
import apiInstance from "../config/api/axios"
import { APIPATH, CASTLERAPIPATH, ISCASTLERACTIVE } from "../constants/Constant"
import { getItem } from "../utilities/authUtils"

export const getUserByToken = async(payload) => {
  const response = await apiInstance.get('/getUserByToken', payload)
  return response
}

export const getOperationData = async(payload) => {
  const response = await apiInstance.get('/v1/admin/adminsdata', payload)
  return response
}

export const getApprovedBorrowerLoanData = async(payload) => {
  const response = await apiInstance.post('/admin/approvedBorrowerloanlist', payload)
  return response
}

export const getUserDetailsById = async(payload) => {
  if (payload.requested_roi) {
    const response = await apiInstance.get(`/admin/get-user-details-by-id?user_id=${payload.user_id}&loan_number=${payload.loan_number}&requested_roi=${payload.requested_roi}`, payload)
    return response
  } else if (payload.type === 'pre-sanction') {
    const response = await apiInstance.get(`/admin/get-user-details-by-id?user_id=${payload.user_id}`, payload)
    return response
  } else {
    const response = await apiInstance.get(`/admin/get-user-details-by-id?user_id=${payload.user_id}&loan_number=${payload.loan_number}`, payload)
    return response
  }
}

export const postBorrowerExpenses = async(payload) => {
  const response = await apiInstance.post('/calculateFOIR_AS_IR', payload)
  return response
}

export const rejectBorrower = async(payload) => {
  const response = await apiInstance.put('/admin/reject-borrower', payload)
  return response
}

export const approveBorrower = async(payload) => {
  const response = await apiInstance.put('/admin/approve-borrower', payload)
  return response
}

export const getNewAccountRequest = async(payload) => {
  const response = await apiInstance.post('/admin/get-new-account-request', payload)
  return response
}

export const getESignedLoanList = async(payload) => {
  const response = await apiInstance.get(`/admin/esignedloanlist?status=${payload.status}`, payload)
  return response
}

export const createENachRequest = async(payload) => {
  const response = await apiInstance.post('/admin/create-enach-request', payload)
  return response
}

export const verifyAgreement = async(payload) => {
  const response = await apiInstance.post('/admin/get-loan-detail', payload)
  return response
}

export const approveUser = async(payload) => {
  const response = await apiInstance.put('/admin/approve-user', payload)
  return response
}

export const rejectLoanContract = async(payload) => {
  const response = await apiInstance.post('/admin/rejectLoanContract', payload)
  return response
}

export const createLoanContract = async(payload) => {
  const ISCASTLERACTIVE = getItem('castlerActive')
  if (ISCASTLERACTIVE === 'true') {
    const response = await apiInstance.post('/v1/castler/createLoanContract', payload)
    return response
  } else {
    const response = await apiInstance.post('/admin/createLoanContract', payload)
    return response
  }
}

export const analayzeBankStatement = async(payload) => {
  const config = {
    responseType: 'blob',
  }
  const response = await apiInstance.post('/v1/admin/bsa/upload/', payload, config)
  return response
}

/// collection APIs
export const fetchCurrentCollection = async (payload) => {
  const response = await apiInstance.post('/admin/getCollection', payload);
  return response;
};
export const fetchCurrentCollection_Loan_id = async (params, payload) => {
  const response = await apiInstance.post(`/admin/getCollection?loan_id=${payload?.loan_id}`, payload);
  return response;
};

export const fetchdata_LoanidDetilsRequest = async (payload) => {
  const response = await apiInstance.post('/admin/collection-details',payload);
  return response;
};


export const fetchDateInitiate = async (payload) => {
  const response = await apiInstance.post('/admin/register-enach-presentation',payload);
  return response;
};

export const fetchClosedLoan = async (query) => {
  const response = await apiInstance.get(`/cp/loanlist?statuses=${query.statuses}&page=${query.page}`)
    return response
};
export const fetchCancelEnachMandate = async (payload) => {
  const response = await apiInstance.post('/admin/cancel-enach-mandate',payload);
  return response
};