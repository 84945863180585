import React from 'react'
import {Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Heading, IconButton, Image, Text, Progress, Flex, Spacer, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel} from '@chakra-ui/react'
import CloseIcon from '../../../../../assets/images/close-side.svg'
import CustomButton from '../../../../elements/customButton'
import UserIcon from "../../../../../assets/images/userIcon.svg";
import moment from 'moment';

export default function ClosedInvestmentSidebar(props) {

    const data = props?.sidebarData
    const currentYear = new Date()
    const getAge = data?.loan_data?.user_data?.dob && Number(moment(currentYear).format("YYYY")) - moment(data?.loan_data?.user_data?.dob).format('YYYY')
    const interestAmount = (data?.loan_data?.product_id === 1) ? data?.loan_contract?.accured_interset_on_emi : data?.loan_contract?.accured_interset_till_date
    const penalInterest = data?.loan_contract?.penel_interset_till_date

    console.log(data, 'data')

  const PopupBox = () => (
    <Box>
      <DrawerOverlay />
      <DrawerContent className='invest_sidebar_content'>
        <Box className="actions_group">
          <IconButton onClick={props.handleCloseSidebar} className="btn_close_sidebar" icon={<Image src={CloseIcon} />} />
        </Box>
        <DrawerHeader className='sidebar_header' pb={0} pt={1}>
            <Box className='profile_info'>
                <Image src={data?.loan_data?.user_data?.photo ? data?.loan_data?.user_data?.photo : UserIcon} className="profile_image" />
                <Box className='user_profile'>
                <Heading as="h3" className='font-poppins'>{data?.loan_data?.user_data?.first_name}{' '}{data?.loan_data?.user_data?.last_name}</Heading>
                <Heading as="h5" className='font-poppins'>{data?.loan_data?.loan_number}</Heading>
                </Box>
            </Box>
        </DrawerHeader>
        <DrawerBody>
            <Box className='lending_amount lending_card_bg lending_card_radius'>
                <Box className='preference_header center_text' mb={2}>
                    <Heading as="h3" className="font-poppins">Total Payout Received </Heading>
                </Box>
                <Heading as="h1" className="font-poppins">
                    ₹{data?.product_id === 1  && data?.loan_data?.loan_contract ? (data?.loan_data?.loan_contract?.payment_amount) : (data?.loan_data?.loan_contract?.pledged_amount + data?.loan_data?.loan_contract?.accured_interset_till_date + data?.loan_data?.loan_contract?.penel_interset_till_date)}
                </Heading>
                <Progress className='custom_progress' bg='#00000014' size='sm' value={data?.loan_data?.committed_loan_amount ? parseFloat(data?.loan_data?.committed_loan_amount) : 0} max={data?.loan_data?.loan_amount} />
                <Flex alignItems='center' mt={1}>
                    <Box className='progress_amount'>
                        <Heading as="h6" className="font-poppins">₹ {data?.loan_data?.committed_loan_amount ? data?.loan_data?.committed_loan_amount : 0}</Heading>
                        <Heading as="p" className="font-poppins">Payout Received</Heading>
                    </Box>
                    <Spacer />
                    <Box className='progress_amount'>
                        <Heading as="h6" className="font-poppins">₹ {data?.loan_data?.remaining_loan_amount}</Heading>
                        <Heading as="p" className="font-poppins">Remaining</Heading>
                    </Box>
                </Flex>
            </Box>
            <Box className='accordion_box lending_card_bg lending_card_radius' mt={1}>
                <Accordion allowToggle>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Loan Details </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                <Text className='font-poppins loan_type'>
                                    {data?.loanData?.[0]?.loan_name}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Invested Amount</Heading>
                                <Text className='font-poppins'>₹{data?.loan_data?.loan_contract?.pledged_amount}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Tenure</Heading>
                                <Text className='font-poppins'>
                                    {data?.loanData?.[0]?.tenure_duration}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Interest Rate p.a.</Heading>
                                <Text className='font-poppins'>
                                    {`${data?.loanData?.[0]?.interest_rate}%`}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>ALPHA Score</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.user_data?.alpha_rating}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Disbursement Date</Heading>
                                <Text className='font-poppins'>
                                    {data?.loan_data?.loan_contract?.funding_date ? moment(data?.loan_data?.loan_contract?.funding_date).format('DD-MM-YYYY') : ''}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Closure Date</Heading>
                                <Text className='font-poppins'>
                                    {data?.loan_data?.loan_contract?.updated_at ? moment(data?.loan_data?.loan_contract?.updated_at).format('DD-MM-YYYY') : ''}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Total Payout Amount</Heading>
                                <Text className='font-poppins'>
                                    ₹{data?.product_id === 1  && data?.loan_data?.loan_contract ? (data?.loan_data?.loan_contract?.payment_amount) : (data?.loan_data?.loan_contract?.pledged_amount + data?.loan_data?.loan_contract?.accured_interset_till_date + data?.loan_data?.loan_contract?.penel_interset_till_date)}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Total Interest Received</Heading>
                                <Text className='font-poppins'>₹{interestAmount}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Penal Interest Amount</Heading>
                                <Text className='font-poppins'>₹ {penalInterest}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Borrower Details </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Full Name</Heading>
                                <Text className='font-poppins loan_type'>{data?.loan_data?.user_data?.first_name}{' '}{data?.loan_data?.user_data?.last_name}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>City Name</Heading>
                                <Text className='font-poppins'>{data?.loanData?.[0]?.personal_data?.address_district}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>CKYC Number</Heading>
                                <Text className='font-poppins'>{data?.loanData?.[0]?.ckyc_detail?.ckyc_number}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Purpose</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.loan_purpose}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>House Ownership</Heading>
                                <Text className='font-poppins'>{data?.loanData?.[0]?.personal_data?.display_premise_status}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Age</Heading>
                                <Text className='font-poppins'>{getAge} Years</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Employment Type</Heading>
                                <Text className='font-poppins'>{data?.loan_data.user_data?.employment_type === '1' ? 'Salaried' : 'Individual'}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>  Risk category & ALPHA Score </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Equifax Score</Heading>
                                <Text className='font-poppins loan_type'>{data?.loanData?.[0]?.credit_score?.credit_score}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>ALPHA Score</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.user_data?.alpha_rating}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
        </DrawerBody>
        <Box className='sidebar_footer' mb={2} mt={0}>
            <Box className='full-width'>
                <CustomButton size="md" type="submit" title="View Agreement" className="btn_theme font-poppins" onClick={() => props.handleViewAgreement(data?.loan_data?.esign_request?.eSign_doc_file)} />
            </Box>
        </Box>
      </DrawerContent>
    </Box>
  )

  return (
    <Drawer
      isOpen={props.openSidebar}
      placement="right"
      onClose={props.handleCloseSidebar}
      size={'md'}
    >
      {PopupBox()}
    </Drawer>
  )
}

