import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem,IconButton, Heading, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import CurrentCollection from './components/CurrentCollection'
import ApprovedBorrowers from './components/Upcoming_Collection.js'
import PendingBorrowers from './components/Missing_Collection.js'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll, removeItem, setItem } from '../../../../utilities/authUtils'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Collection_Loan_Details from './components/Collection_Loan_Details.js.js'
import Upcoming_Collection from './components/Upcoming_Collection.js'
import Missing_Collection from './components/Missing_Collection.js'
import Closed_Collection from './components/Closed_Collection.js'
import ClosedLoan from './components/ClosedLoan.js'

const tabNames = [
    {
        id: 1,
        name: 'Current',
    },
    {
        id: 2,
        name: 'Upcoming',
    },
    {
        id: 3,
        name: 'Missing',
    },
    {
        id: 4,
        name: 'Closed',
    },
    {
        id: 5,
        name: 'Closed Loan',
    },
]

export default function Collections(props) {
    const history = useHistory()
    const adminState = useSelector((state) => state.adminState)
    const filterData = adminState?.getCurrentCollection?.data?.filterData || [];
    const [selectedTab, setSelectedTab] = useState(tabNames[0].name)
    const [inputValues, setInputValues] = useState({})
    const userData = adminState?.getUserByToken?.data?.data
    const userType = userData?.usertype
    const[selectedItem,setSelectedItem] =useState(null)
    const handleTabChange = (index) => {
        
        setSelectedTab(tabNames[index].name);
    }
    const handleVerify = (id,borr,type) => {
        setItem('loan_ID', id)
        setItem("boRRower_ID",borr )
        setItem("loandetails_type",type )
        if((selectedTab === 'Current' || selectedTab === 'Upcoming' || selectedTab === 'Missing' || selectedTab === 'Closed' || selectedTab === 'Closed Loan' )) {
            history.push(routesNames.ADMINUSERCOLLECTION_LOAN_DETAILS);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({...inputValues, [name]: value})
    }

    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }

  return (
    <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={8}>
                <Heading as="h2" className='font-poppins page_heading'> Collection Data</Heading>
            </GridItem>
            {/* <GridItem colSpan={4}>
                <NavBar data={userData} isAdmin />
            </GridItem> */}
        </Grid>
        <Box mt={5}>
            <Tabs className='tabs_container' onChange={handleTabChange}>
                <TabList className='tabs_list'>
                    {tabNames?.map((item, index) => {
                        return (
                            <Tab key={index}>{item.name}</Tab>
                        )
                    })}
                </TabList>
                <TabPanels>
                    {tabNames.map((item, index) => {
                        return (
                            <TabPanel key={index} className='tab_panel'>
                                {item.name === 'Current' &&
                                    <CurrentCollection
                                        tabSelected={selectedTab}
                                        handleVerify={handleVerify}
                                        inputValues={inputValues}
                                        handleChange={handleChange}
                                        userType={userType}
                                        // filterData={filterData} 
                                    />
                                }
                                {item.name === 'Upcoming' &&
                                    <Upcoming_Collection
                                        tabSelected={selectedTab}
                                        inputValues={inputValues}
                                        handleChange={handleChange}
                                        handleVerify={handleVerify}
                                    />
                                }
                                {item.name === 'Missing' &&
                                    <Missing_Collection
                                        tabSelected={selectedTab}
                                        inputValues={inputValues}
                                        handleChange={handleChange}
                                        handleVerify={handleVerify}
                                    />
                                }
                                {item.name === 'Closed' &&
                                    <Closed_Collection
                                        tabSelected={selectedTab}
                                        inputValues={inputValues}
                                        handleChange={handleChange}
                                        handleVerify={handleVerify}
                                    />
                                }
                                {item.name === 'Closed Loan' &&
                                    <ClosedLoan
                                        tabSelected={selectedTab}
                                        inputValues={inputValues}
                                        handleChange={handleChange}
                                        handleVerify={handleVerify}
                                    />
                                }
                            </TabPanel>
                        )
                    })}
                </TabPanels>
            </Tabs>
        </Box>

            {/* Render Collection_Loan_Details if selectedItem is not null */}
            {selectedItem && <Collection_Loan_Details filterData={selectedItem} />}
    </Box>
  )
}
