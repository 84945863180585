import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Table,
  TableContainer,
  IconButton,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button
} from "@chakra-ui/react";
import {fetchDateInitiate} from '../../../../../services/adminServices.js'
import CustomButton from "../../../../elements/customButton";
import Pagination from "../../../../elements/Pagination";
import { ChevronRightIcon } from "@chakra-ui/icons";
import moment from "moment";
import Loader from "../../../../elements/loader";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../../../redux/actions";
import { connect, useSelector } from "react-redux";
import NoData from "../../../../elements/NoData";
import * as routesNames from "../../../../../constants/routes";
import { removeItem, setItem } from "../../../../../utilities/authUtils.js";
import CustomToast from "../../../../elements/customToast.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const Missing_Collection = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [disbled_Staus, setdisbled_Staus] = useState();
  const [disabledEmiId, setDisabledEmiId] = useState(null); // To track which row's button is clicked
  const current_Collection = adminState?.getCurrentCollection?.data?.data;
  const Reinitiate_Data = adminState?.getReiniateacData?.data?.message;
  
  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(filterData?.length / PER_PAGE);
  const {addToast} = CustomToast()
  useEffect(() => {
    if (props.tabSelected === "Missing") {
      let data = {
        type: 3,
      };
      props?.actions?.getCurrentCollectionAction(data);
    }
    removeItem("ckyc_number")
  }, [props.tabSelected]);

  useEffect(() => {
    if (adminState?.getCurrentCollection?.data?.success === true) {
      setFilterData(current_Collection);
    }
  }, [adminState?.getCurrentCollection]);
  useEffect(() => {
    if (adminState?.getReiniateacData?.data?.success === true) {
      addToast({message: Reinitiate_Data, status: 'success'})
      window.location.reload()
    }
    else if (adminState?.getReiniateacData?.data?.success === false){
      addToast({message: Reinitiate_Data, status: 'error'})
    }

  }, [adminState?.getReiniateacData]);
  const handleSearch = (e) => {
    const { value } = e.target;
    if (value !== "") {
      const result = current_Collection.filter((item) => {
        return (
          item?.mobile?.toLowerCase().includes(value.toLowerCase()) ||
          item?.first_name?.toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilterData(result);
    } else {
      setFilterData(current_Collection);
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };
  const reinitiate_payment =(id)=>{
    let param={
      emi_id:id
    }
    setDisabledEmiId(id); // Disable the clicked button immediately
    fetchDateInitiate(param).then((res)=>{
      if (res?.data?.success === true) {
        setdisbled_Staus(res)
        
        addToast({message:res?.data?.message,status:"success"})
        props?.actions?.cleanUpToast()
        props.actions.getCurrentCollectionAction({type:3})
      }
      else{
        addToast({message:res?.data?.message,status:"error"})
        setDisabledEmiId(null); 
        props?.actions?.cleanUpToast()
      }
    }).catch((error) => {
      addToast({message: 'Getting Invalid Response From Bank', status: 'error'})
      setDisabledEmiId(null); 

})
  }
  return (
    <>
      {(adminState?.getCurrentCollection?.loading ||
        adminState?.getUserByToken?.loading) && (
        <div>
          <Loader />
        </div>
      )}
      <Box mt={1}>
        <Box className="search_box">
          <Input
            type="text"
            size="md"
            name="search"
            placeholder="Search"
            onChange={handleSearch}
            className="input_box font-poppins"
          />
        </Box>
        <>
          {filterData?.length > 0 ? (
            <Box mt={2} className="custom_table_wrapper custom_table_wrapper2">
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>S.No.</Th>
                      <Th>Atomisation No. </Th>
                      <Th>Loan Details</Th>
                      <Th>Loan Contract</Th>
                      <Th>Loan Disbursed Date</Th>
                      <Th>Loan EMI Date</Th>
                      <Th>Loan Presentation date</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                      <Th>Presentation Status</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filterData
                      ?.slice(offset, offset + PER_PAGE)
                      ?.map((item, index) => {
                        const serialNumber = offset + index + 1;
                        return (
                          <Tr key={index}>
                            <Td>
                              <Text className="font-poppins table_text">
                                {serialNumber}
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.amortization_txnID ? item?.amortization_txnID : "NA"}
                              </Text>
                            </Td>
                            <Td>
                            <Text className="font-poppins  gray_text">
                                {item?.UMRN ? item?.UMRN : "NA"}
                              </Text>
                              <Td>
                              
                              <Text className="font-poppins table_text">
                                {item?.loan_number ? item?.loan_number : "NA"}
                              </Text>
                            </Td>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.loan_contract_number
                                  ? item?.loan_contract_number
                                  : "Not Assigned"}
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.disbursed_date
                                  ? moment(item?.disbursed_date).format(
                                      "DD-MMM-YYYY"
                                    )
                                  : "Not Available"}
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.emi_date
                                  ? moment(item?.emi_date).format("DD-MMM-YYYY")
                                  : "Not Available"}
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.presentation_date
                                  ? moment(item?.presentation_date).format(
                                      "DD-MMM-YYYY"
                                    )
                                  : "Not Available"}
                              </Text>
                            </Td>
                            <Td>
                                      <Text  className="font-poppins table_text">
                                      {item?.display_status}
                                      </Text>
                              <Text className="font-poppins red_text" style={{fontSize:"10px"}}>
                                {item?.failure_response ? item?.failure_response : ""}
                              </Text>
                         
                            </Td>
                            
                            <Td>
                            {(item?.display_status == "pending"  || item?.display_status == "failed" || item?.display_status == "payment_failed") ? 
                                <Button size="md"  className="btn_table_dark_red-collection font-poppins"  disabled={disabledEmiId === item?.emi_id}   onClick={()=>reinitiate_payment(item?.emi_id)}>
                                 Re-initiate Presentation
                            </Button>
                            :  
                            <Button size="md"  className="btn_table_dark_red-collection font-poppins" disabled ={true}>
                                 Re-initiate Presentation 
                            </Button> 
                                    }
                            </Td>
                            <Td>
                                      <Text  className="font-poppins presentation">
                                      {item?.presentation_date ? item?.presentation_status :"New"}
                                      </Text>
                                      <Td>
                                      <Text className="font-poppins red_text" style={{fontSize:"10px"}}>
                                {item?.failure_response ? item?.failure_response : ""}
                              </Text>
                            </Td>
                                      </Td>
                            <Td>
                           
                              <Box className="actions_td action_table">
                                <IconButton
                                  onClick={() =>
                                    props.handleVerify(item?.loan_id,item?.borrower_id,3)
                                  }
                                >
                                  <ChevronRightIcon />
                                </IconButton>
                              </Box>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            </Box>
          ) : (
            <NoData title="No Record Found" />
          )}
        </>
      </Box>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(Missing_Collection);
