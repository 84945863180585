import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import SigningInProcess from './components/SigningInProcess'
import PendingDisbursal from './components/PendingDisbursal'
import ApprovedAgreements from './components/ApprovedAgreements'
import ExpiredAgreements from './components/ExpiredAgreements'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll, setItem } from '../../../../utilities/authUtils'
import { ActionCreators } from '../../../../redux/actions'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import Loader from '../../../elements/loader'
import EnachDetails from './components/ENachDetails'
import CustomToast from '../../../elements/customToast'
import { createENachRequest } from '../../../../services/adminServices'
import VerifyEnachDetailsDialog from './components/VerifyEnachDetailsDialog'

const tabNames = [
    {
      id: 1,
      name: 'Signing In Progress',
  },
    {
      id: 2,
      name: 'E-Nach',
    },
    {
      id: 3,
      name: 'Pending For Disbursal',
    },
    {
      id: 4,
      name: 'Approved Agreement',
    },
    {
      id: 5,
      name: 'Expired Agreements',
    },
  ]

const LoanAgreements = (props) => {

  const history = useHistory()
  const {addToast} = CustomToast()
  const adminState = useSelector((state) => state.adminState)
  const masterState = useSelector((state) => state.masterState)
  const [isLoading, setIsLoading] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [ENachData, setENachData] = useState(null)
  const [ENachModeData, setEModeNachData] = useState(null)
  const [inputValues, setInputValues] = useState({})
  const [filterSigninData, setFilterSigninData] = useState([])
  const [filterENachData, setFilterENachData] = useState([])
  const [filterPendingData, setFilterPendingData] = useState([])
  const [filterApprovedData, setFilterApprovedData] = useState([])
  const [filterExpiredData, setFilterExpiredData] = useState([])
  const userData = adminState?.getUserByToken?.data?.data
  const userType = userData?.usertype
  const agreementsData = adminState?.getESignedLoanList?.data?.data
  const signedAgreements = agreementsData?.filter((item) => item.loan_status === '3')
  const pendingAgreements = agreementsData?.filter((item) => item.loan_status === '4')
  const approvedAgreements = agreementsData?.filter((item) => item.loan_status === '5')
  const expiredAgreements = agreementsData?.filter((item) => item.loan_status === '11')
  const dropdownData = masterState?.getDropdownData?.data?.data
  const enachAuthModeOption = dropdownData?.enach_auth_mode
  useEffect(() => {
    props?.actions?.getESignedLoanListAction({status: 'all'})
    props.actions.getUserByTokenAction()
    props.actions.getDropdownDataAction({ type: 'enach_auth_mode', platform:'web'})
  }, [])

  useEffect(()=>{
    setEModeNachData(enachAuthModeOption)
  },[])
  useEffect(() => {
    if (adminState?.getESignedLoanList?.data?.success === true) {
      setFilterSigninData(signedAgreements)
      setFilterENachData(pendingAgreements)
      setFilterPendingData(pendingAgreements)
      setFilterApprovedData(approvedAgreements)
      setFilterExpiredData(expiredAgreements)
    }
  }, [adminState?.getESignedLoanList])

  const additionAmount = (ENachData?.loan_amount * 25)/100;
  const loanAmount = Number(ENachData?.loan_amount)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value })
  }

  const handleSearchSignin = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = signedAgreements.filter((item) => {
        return item?.user_data?.escrow_account_id?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.first_name?.toLowerCase().includes(value.toLowerCase()) || item?.loan_number?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterSigninData(result)
    } else {
      setFilterSigninData(signedAgreements)
    }
  }

  const handleSearchENach = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = pendingAgreements.filter((item) => {
        return item?.loan_number?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.first_name?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.mobile?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterENachData(result)
    } else {
      setFilterENachData(pendingAgreements)
    }
  }

  const handleSearchPending = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = pendingAgreements.filter((item) => {
        return item?.user_data?.escrow_account_id?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.first_name?.toLowerCase().includes(value.toLowerCase()) || item?.loan_number?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterPendingData(result)
    } else {
      setFilterPendingData(pendingAgreements)
    }
  }

  const handleSearchApproved = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = approvedAgreements.filter((item) => {
        return item?.user_data?.escrow_account_id?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.first_name?.toLowerCase().includes(value.toLowerCase()) || item?.loan_number?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterApprovedData(result)
    } else {
      setFilterApprovedData(approvedAgreements)
    }
  }

  const handleSearchExpired = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = expiredAgreements.filter((item) => {
        return item?.user_data?.escrow_account_id?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.first_name?.toLowerCase().includes(value.toLowerCase()) || item?.loan_number?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterExpiredData(result)
    } else {
      setFilterExpiredData(expiredAgreements)
    }
  }

  const handleOpenDetails = (item) => {
    const loanAmount = parseFloat(item?.loan_amount) || 0;
    const additionAmount = (loanAmount * 25) / 100;
    const totalAmount = loanAmount + additionAmount;
    setOpenDetails(true)
    setENachData(item)
    setInputValues({ ...inputValues, loan_amount: totalAmount })
  }

  const handleCloseDetails = () => {
    setOpenDetails(false)
    setENachData(null)
    setInputValues({})
  }

  const generateENach = () => {
    let obj = {
      loan_id: ENachData?.id,
      loan_amount: parseFloat(inputValues?.loan_amount),
      auth_mode: inputValues?.auth_mode
    }
    if(!obj?.auth_mode ){
      addToast({message: 'Please auth mode', status: 'error'})
      setIsLoading(false)
    }
    else{
      setIsLoading(true)
      createENachRequest(obj).then((res) => {
          setIsLoading(false)
          if (res?.data?.success === true && res?.data?.status === true) {
              addToast({ message: res?.data?.msg, status: 'success' })
              setOpenDetails(false)
              setENachData(null)
              setInputValues({})
          } else if (res?.data?.status === false) {
            addToast({message: res?.data?.msg, status: 'error'})
          }
        }).catch((error) => {
          addToast({message: 'Something Went Wrong', status: 'error'})
          setIsLoading(false)
      })
    }
   
  }

  const handleVerifyApplication = (id) => {
    setItem('loanId', id)
    history.push(routesNames.POSTSANCTIONLOANAGREEMENTSVERIFY)
  }

  const handleViewApplication = (id) => {
    setItem('loanId', id)
    history.push(routesNames.POSTSANCTIONLOANAGREEMENTSVIEW)
  }

  // const handleLogout = () => {
  //   removeAll()
  //   history.push(routesNames.LOGIN)
  // }

  return (
  <>
    {(adminState?.getESignedLoanList?.loading || isLoading || adminState?.verifyAgreement?.loading || adminState?.getUserByToken?.loading) &&
    <div>
      <Loader />
    </div>
    }
    <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={8}>
              <Heading as="h2" className='font-poppins page_heading'>Loan Agreements</Heading>
            </GridItem>
            <GridItem colSpan={4}>
              <NavBar data={userData} isAdmin />
            </GridItem>
        </Grid>
        <Box mt={5}>
          <Tabs className='tabs_container'>
              <TabList className='tabs_list'>
                  {tabNames.map((item, index) => {
                    return (
                      <Tab key={index}>{item.name}</Tab>
                    )
                  })}
              </TabList>
              <TabPanels>
                  {tabNames.map((item, index) => {
                    return (
                      <TabPanel key={index} className='tab_panel'>
                        {item.name === 'Signing In Progress' &&
                          <SigningInProcess
                            filterSigninData={filterSigninData}
                            handleSearch={handleSearchSignin}
                          />
                        }
                        {item.name === 'E-Nach' &&
                        <EnachDetails
                          userType={userType}
                          handleOpenDetails={handleOpenDetails}
                          filterENachData={filterENachData}
                          handleSearch={handleSearchENach}
                        />
                        }
                        {item.name === 'Pending For Disbursal' &&
                          <PendingDisbursal 
                            handleVerifyApplication={handleVerifyApplication}
                            userType={userType}
                            filterPendingData={filterPendingData}
                            handleSearch={handleSearchPending}
                          />
                        }
                        {item.name === 'Approved Agreement' &&
                          <ApprovedAgreements
                            filterApprovedData={filterApprovedData}
                            handleSearch={handleSearchApproved}
                            handleViewApplication={handleViewApplication}
                          />
                        }
                        {item.name === 'Expired Agreements' &&
                          <ExpiredAgreements
                            filterExpiredData={filterExpiredData}
                            handleSearch={handleSearchExpired}
                          />
                        }
                      </TabPanel>
                    )
                  })}
              </TabPanels>
          </Tabs>
        </Box>
    </Box>
    <VerifyEnachDetailsDialog  enachAuthModeOption={enachAuthModeOption}  openDetails={openDetails} ENachData={ENachData} inputValues={inputValues}
      additionAmount={additionAmount} loanAmount={loanAmount}
      generateENach={generateENach} handleCloseDetails={handleCloseDetails} handleChange={handleChange}
    />
  </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(LoanAgreements)