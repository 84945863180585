import axios from "axios"
import apiInstance from "../config/api/axios"
import { APIPATH, CASTLERAPIPATH, ISCASTLERACTIVE } from "../constants/Constant"
import { getItem } from "../utilities/authUtils"

export const getUserInvestorDetails = async(payload) => {
  const response = await apiInstance.get('/user-dashboard-details', payload)
  return response
}

export const getInvestmentOpportunities = async(payload) => {
  const response = await apiInstance.get('investor/investment-opportunities', payload)
  return response
}

export const getQuickInvestment = async(payload) => {
  const response = await apiInstance.get(`investor/investment-opportunities?pref_risk_appetite=${payload.pref_risk_appetite}&pref_employment_type=${payload.pref_employment_type}&pref_tenure=${payload.pref_tenure}&pref_min_interest_rate=${payload.pref_min_interest_rate}&pref_max_interest_rate=${payload.pref_max_interest_rate}&pref_income_range=${payload.pref_income_range}&pref_invest_amount=${payload.pref_invest_amount}`, payload)
  return response
}

export const getInvestmentPortfolio = async(payload) => {
  const response = await apiInstance.get('/getPortfolio', payload)
  return response
}

export const getwalletbalance = async(payload) =>{
  const response = await apiInstance.get('/getwalletbalance', payload)
  return response
}

export const getWalletTransaction = async(payload) =>{
  const response = await apiInstance.get('/getwallettransaction', payload)
  return response
}

export const getInvestmentDetails = async(payload) =>{
  const response = await apiInstance.get(`/investor/get-investment?status=${payload.status}`, payload)
  return response
}

export const getInvestorLoanListStatus = async(payload) => {
  const response = await apiInstance.get(`investor/loanlist?statuses=${payload.status}`, payload)
  return response
}

export const InvestInLoan = async(payload) =>{
 const response = await apiInstance.post('investor/invest-in-loan', payload)
 return response
}

export const getWalletPassbook = async(payload) =>{
  const response = await apiInstance.post('getWalletPassbook', payload)
  return response
}

export const withdrawWalletAmount = async(data) => {
  const ISCASTLERACTIVE = getItem('castlerActive')
  if (ISCASTLERACTIVE === 'true') {
    const response = await apiInstance.post('/v1/castler/withdrawWalletAmount', data)
    return response
  } else {
    const response = await apiInstance.post('/withdrawWalletAmount', data)
    return response
  }
}

export const postNomineeDetails = async(payload) =>{
  const response = await apiInstance.post('investor/nominee-details', payload)
  return response
}

export const changePaymentMode = async(payload) =>{
  const response = await apiInstance.post('investor/change-payment-transfer-mode', payload)
  return response
}