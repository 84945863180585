import React, { useEffect, useRef, useState } from 'react'
import {Box, Grid, GridItem, Heading, Image, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import { removeAll } from '../../../../utilities/authUtils'
import {useHistory, Link} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import IconkYC from '../../../../assets/images/Iconkyc.svg'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import InstaLoanEmiCalculator from './components/InstaLoanEmiCalculator'
import BulletLoanEmiCalculator from './components/BulletLoanEmiCalculator'
import PersonalLoanEmiCalculator from './components/PersonalLoanEmiCalculator'
import Loader from '../../../elements/loader'
import LoanCard from './components/LoanCard'
import CustomToast from '../../../elements/customToast'
import ImportantDialog from '../../../elements/ImportantDialog'
import LoanAppliedDialog from '../components/LoanAppliedDialog'
import RejectedIcon from '../../../../assets/images/decline.svg'

const tabNames = [
  {
      id: 1,
      name: 'Insta Loan',
  },
  {
      id: 2,
      name: 'Bullet Loan',
  },
  {
      id: 3,
      name: 'Personal Loan',
  },
]

const ApplyLoan = (props) => {

  const { addToast } = CustomToast()
  const history = useHistory()
  const bankRef = useRef()
  const incomeRef = useRef()
  const itrRef = useRef()
  const borrowerState = useSelector((state) => state.borrowerState)
  const masterState = useSelector((state) => state.masterState)
  const [inputValues, setInputValues] = useState({})
  const [activeTab, setActiveTab] = useState(0);
  const [productId, setProductId] = useState(null)
  const [bankStatement, setBankStatement] = useState(null)
  const [incomeStatement, setIncomeStatement] = useState(null)
  const [itrStatement, setItrStatement] = useState(null)
  const [loanSuccess, setLoanSuccess] = useState(false)
  const [instaLoanDetails, setInstaLoanDetails] = useState({
    loanAmount: [0, 7000],
    roi: [0, 0.2],
    tenure: [0, 10],
  });
  const [bulletLoanDetails, setBulletLoanDetails] = useState({
    loanAmount: [0, 30000],
    roi: [0, 18],
    tenure: [0, 240],
  });
  const [personalLoanDetails, setPersonalLoanDetails] = useState({
    loanAmount: [0, 40000],
    roi: [0, 24],
    tenure: [0, 9],
  });
  const [expectedROI, setExpectedROI] = useState({roi: [0, 24.5]});
  const [loanRange, setLoanRange] = useState(null);
  const [loanTenure, setLoanTenure] = useState([0, 0]);

  const userDetails = borrowerState?.getUserDashboardDetails?.data?.data
  const userData = masterState?.getCurrentUserDetails?.data?.data
  const dropdownData = masterState?.getDropdownData?.data?.data
  const productData = borrowerState?.getProductList?.data?.data
  const loanListStatusData = borrowerState?.getLoanListStatus?.data?.data
  const isKYCPending = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 0)
  const isKYCUnderReview = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 1)
  const isLoanApplied = (loanListStatusData?.[0]?.loan_status === '0' || loanListStatusData?.[0]?.loan_status === '1' || loanListStatusData?.[0]?.loan_status === '2' || loanListStatusData?.[0]?.loan_status === '3' || loanListStatusData?.[0]?.loan_status === '4')
  // const isLoanRejected = borrowerState?.getLoanListStatus?.data?.is_loan_applicable
  const isPersonalLoan = productData?.find((item) => item.product_id === 1)
  const isBulletLoan = productData?.find((item) => item.product_id === 2)
  const isInstaLoan = productData?.find((item) => item.product_id === 3)
  const loanTenureData = isPersonalLoan?.max_tenure_limit && JSON.parse(isPersonalLoan?.max_tenure_limit)

  useEffect(() => {
    props.actions.getUserDashboardDetailsAction()
    props.actions.getCurrentUserDetailsAction()
    props.actions.getDropdownDataAction({ type: 'loan_purpose|company_sector|work_exp_level|premise_status|educational_qualification' })
    props.actions.getProductListAction()
    props.actions.getLoanListStatusAction({status: '0,1,2,3,4'})
  }, []);

  useEffect(() => {
    setInputValues({
      ...inputValues,
      premise_status: userData?.[0]?.personal_data?.premise_status,
      educational_qualification: userData?.[0]?.personal_data?.educational_qualification,
    })
  }, [userData])

  useEffect(() => {
    if (borrowerState?.applyLoan?.data?.success === true){
      addToast({message: borrowerState?.applyLoan?.data?.message, status: 'success'})
      props?.actions?.cleanUpToast()
      setInputValues({})
      setBankStatement(null)
      setIncomeStatement(null)
      setItrStatement(null)
      setProductId(null)
      props.actions.getLoanListStatusAction({status: '0,1,2,3'})
      props.actions.getCurrentUserDetailsAction()
      props.actions.getDropdownDataAction({ type: 'loan_purpose|company_sector|work_exp_level|premise_status|educational_qualification' })
      setLoanSuccess(true)
    } else if (borrowerState?.applyLoan?.data?.success === false){
      addToast({message: borrowerState?.applyLoan?.data?.message, status: 'error'})
      props?.actions?.cleanUpToast()
      props.actions.getDropdownDataAction({ type: 'loan_purpose|company_sector|work_exp_level|premise_status|educational_qualification' })
    }
  }, [borrowerState?.applyLoan?.data])

  const handleLoans = (id) => {
    if (id === productId) {
      setProductId(null);
      setActiveTab(0);
    } else {
      setProductId(id);
      if (id === 1) {
        setActiveTab(2);
      } else if (id === 2) {
        setActiveTab(1);
      } else {
        setActiveTab(0);
      } 
    }
  }

  const handleTabChange = (index) => {
    setActiveTab(index);
  }

  const handleChangeInstaLoan = (e, type) => {
    const updatedDetails = {...instaLoanDetails, [type]: e,}
    setInstaLoanDetails(updatedDetails)
  }

  const handleChangeBulletLoan = (e, type) => {
    const updatedDetails = {...bulletLoanDetails, [type]: e,}
    setBulletLoanDetails(updatedDetails)
  }

  const handleChangePersonalLoan = (e, type) => {
    const updatedDetails = {...personalLoanDetails, [type]: e,}
    setPersonalLoanDetails(updatedDetails)
  }

  const handleExpectedROI = (e, type) => {
    const updatedDetails = {...expectedROI, [type]: e,}
    setExpectedROI(updatedDetails)
  }

  const handleLoanCalculator = () => {
    if (activeTab === 0) {
      let totalPayableInterest = ((instaLoanDetails.roi[1]) * parseInt(instaLoanDetails.loanAmount[1]) * parseInt(instaLoanDetails.tenure[1])) / 100;
      let totalAmt = totalPayableInterest + parseInt(instaLoanDetails.loanAmount[1])
      return {totalInterest : totalPayableInterest.toFixed(2), totalAmount: totalAmt.toFixed(2)};
    }
    if (activeTab === 1) {
      let totalPayableInterest = (((parseInt(bulletLoanDetails.roi[1]) / 365) * parseInt(bulletLoanDetails.loanAmount[1]))/100) * parseInt(bulletLoanDetails.tenure[1]);
      let totalAmt = totalPayableInterest + parseInt(bulletLoanDetails.loanAmount[1])
      return {totalInterest : totalPayableInterest.toFixed(2), totalAmount: totalAmt.toFixed(2)};
    }
    if (activeTab === 2) {
      var interest = parseInt(personalLoanDetails.roi[1]) / 1200;
      var emi = Math.round(parseInt(personalLoanDetails.loanAmount[1]) * interest / (1 - (Math.pow(1 / (1 + interest), parseInt(personalLoanDetails.tenure[1])))) * 100) / 100;
      var totalPayable = Math.round((emi * parseInt(personalLoanDetails.tenure[1])) * 100) / 100;
      var interestPayable = Math.round((totalPayable * 1 - parseInt(personalLoanDetails.loanAmount[1]) * 1) * 100) / 100;
      return {emi : emi, totalPayable : totalPayable, interestPayable : interestPayable, interestRate : interest };
    }
  }

  const emiCalculator = handleLoanCalculator()

  const showOpenFileDialog = (type) => {
    if (type === 'bank_statement') {
      bankRef.current.click()
      return
    }
    if (type === 'income_proof') {
      incomeRef.current.click()
      return
    }
    if (type === 'itr_proof') {
      itrRef.current.click()
      return
    }
  }

  const onChangeFile = (e, type) => {
    console.log(e.target.files[0], 'e.target.files[0]')
    if (type === 'bank_statement') {
      setBankStatement(e.target.files[0])
      if (e.target.files[0].type !== 'application/pdf') {
        addToast({message: 'Bank Statement must be a file of type: pdf. ', status: 'error'});
        return
      }
    }
    if (type === 'income_proof') {
      setIncomeStatement(e.target.files[0])
      if (e.target.files[0].type !== 'application/pdf') {
        addToast({message: 'Income Proof must be a file of type: pdf. ', status: 'error'});
        return
      }
    }
    if (type === 'itr_proof') {
      setItrStatement(e.target.files[0])
      if (e.target.files[0].type !== 'application/pdf') {
        addToast({message: 'ITR Proof must be a file of type: pdf. ', status: 'error'});
        return
      }
    }
  }

  // const findLoanRange = (amount) => {
  //   for (let i = 0; i < loanTenureData.length; i++) {
  //     const minAmount = parseInt(loanTenureData[i].min_amount);
  //     const maxAmount = parseInt(loanTenureData[i].max_amount);
  //     if (amount >= minAmount && amount <= maxAmount) {
  //       return loanTenureData[i];
  //     }
  //   }
  //   return null; // Return null if no range is found
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
    // const amount = parseInt(value);
    // if (!isNaN(amount)) {
    //   const range = findLoanRange(amount);
    //   setLoanRange(range);
    // } else {
    //   setLoanRange(null);
    // }
    if (name === 'loan_amount') {
      const loanAmount = parseInt(value, 10);
      const loanRange = loanTenureData.find((range) => loanAmount >= parseInt(range.min_amount) && loanAmount <= parseInt(range.max_amount));
      setLoanRange({
        min_tenure: loanRange ? parseInt(loanRange.min_tenure) : 0,
        max_tenure: loanRange ? parseInt(loanRange.max_tenure) : 0,
      });
      // Reset loan tenure when loan amount changes
      setLoanTenure([loanRange ? parseInt(loanRange.min_tenure) : 0, loanRange ? parseInt(loanRange.max_tenure) : 0]);
    }
  }

  const handleLoanTenure = (e) => {
    setLoanTenure(e)
  }

  const handleApplyLoan = (loanTypeId) => {
    const loanAmount = Number(inputValues?.loan_amount);
    const isMultipleOfThousand = loanAmount % 1000 === 0;
    if (!inputValues?.loan_amount) {
      addToast({message: 'Please Enter Loan Amount', status: 'error'})
      return
    }
    if (loanTypeId === 3) {
      if (!isMultipleOfThousand) {
        addToast({message: 'Loan Amount Should be multiple of ₹1000', status: 'error'});
        return
      }
      if (loanAmount < 5000) {
        addToast({message: 'Minimum loan amount is ₹5000', status: 'error'});
        return
      }
      if (loanAmount > 10000) {
        addToast({message: 'Maximum loan amount is ₹10000', status: 'error'});
        return
      }
    }
    if (loanTypeId === 2) {
      if (loanAmount < 10000) {
        addToast({message: 'Minimum loan amount is ₹10000', status: 'error'});
        return
      }
      if (loanAmount > 50000) {
        addToast({message: 'Maximum loan amount is ₹50000', status: 'error'});
        return
      }
    }
    if (loanTypeId === 1) {
      if (loanAmount < 10000) {
        addToast({message: 'Minimum loan amount is ₹10000', status: 'error'});
        return
      }
      if (loanAmount > 300000) {
        addToast({message: 'Maximum loan amount is ₹300000', status: 'error'});
        return
      }
    }
    if ((loanTypeId === 3 || loanTypeId === 2) && !inputValues?.loan_tenure) {
      addToast({message: 'Please Enter Loan Tenure', status: 'error'})
      return
    }
    if (!inputValues?.loan_purpose) {
      addToast({message: 'Please Select Loan Purpose', status: 'error'})
      return
    }
    if (!inputValues?.current_company_name) {
      addToast({message: 'Please Enter Current Company Name', status: 'error'})
      return
    }
    if (!inputValues?.company_sector) {
      addToast({message: 'Please Select Company Sector', status: 'error'})
      return
    }
    if (!inputValues?.work_experience) {
      addToast({message: 'Please Select Total Work Experience', status: 'error'})
      return
    }
    if (!inputValues?.monthly_income) {
      addToast({message: 'Please Enter Monthly Income', status: 'error'})
      return
    }
    if (!inputValues?.premise_status) {
      addToast({message: 'Please Enter House Ownership', status: 'error'})
      return
    }
    if (!inputValues?.educational_qualification) {
      addToast({message: 'Please Enter Education Qualification', status: 'error'})
      return
    }
    if (loanTypeId !== 3) {
      if (!bankStatement) {
        addToast({message: 'Please Upload Bank Statement', status: 'error'})
        return
      }
      if (userData?.[0]?.employment_type === '1') {
        if (!incomeStatement) {
          addToast({message: 'Please Upload Income Proof / Salary Slip', status: 'error'})
          return
        }
      }
    }
    let formData = new FormData();
    formData.append("loan_type", loanTypeId);
    formData.append("loan_amount", inputValues?.loan_amount);
    formData.append("tenure", loanTypeId === 1 ? loanTenure[1] : inputValues?.loan_tenure);
    formData.append("loan_purpose", inputValues?.loan_purpose);
    formData.append("premise_status", inputValues?.premise_status ?? userData?.personal_data?.premise_status);
    formData.append("educational_qualification", inputValues?.educational_qualification ?? userData?.personal_data?.educational_qualification);
    formData.append("company_name", inputValues?.current_company_name);
    formData.append("company_sector", inputValues?.company_sector);
    formData.append("work_exp_level", inputValues?.work_experience);
    formData.append("net_monthly_income", inputValues?.monthly_income);
    formData.append("disbursal_consent", 'Y');
    if (loanTypeId !== 3){
      formData.append('bank_statement_1', bankStatement ?? null)
      formData.append('itr_form16', itrStatement ?? null)
      formData.append('salary_slip_1', incomeStatement ?? null)
      formData.append('expected_roi', expectedROI?.roi[1] ?? null)
    }
    props.actions.applyLoanAction(formData)
  }

  const handleCloseLoanSuccess = () => {
    setLoanSuccess(false)
  }

  const handleGoToLoanStatus = () => {
    history.push(routesNames.BORROWERLOANSTATUS)
  }

  const handleProfile = () => {
    history.push(routesNames.BORROWERPROFILE)
  }

  // const handleLogout = () => {
  //   removeAll()
  //   history.push(routesNames.LOGIN)
  // }

  return (
    <>
      {(masterState?.getCurrentUserDetails?.loading || borrowerState?.getProductList?.loading || borrowerState?.applyLoan?.loading) &&
        <div>
            <Loader />
        </div>
      }
      <Box className='dashboard_box bg_shadow'>
          <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={8}>
              <Box className='d-flex align-items-center justify-content-between'>
                <Heading as="h2" className='font-poppins page_heading'>Loan Applications</Heading>
                <ImportantDialog />
              </Box>
            </GridItem>
            <GridItem colSpan={4}>
              <NavBar data={userData} handleProfile={handleProfile} />
            </GridItem>
          </Grid>
          <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={7}>
              {isKYCUnderReview ?
                <Box mt={4} className='kyc_verify'>
                  <Heading as="h3" className='font-poppins'>
                    <Image src={IconkYC} />
                    Your documents under review
                    <Link to={routesNames.BORROWERKYC}>Tap to check your documents</Link>
                  </Heading> 
                </Box> : isKYCPending ?
                <Box mt={4} className='kyc_verify'>
                  <Heading as="h3" className='font-poppins'>
                    <Image src={IconkYC} />
                    Your ALPHAMONEY account needs Verification,
                    <Link to={routesNames.BORROWERKYC}>Tap to start your KYC</Link>
                  </Heading>
                </Box> : null
              }
              {isLoanApplied ?
                <Box mt={4} className='check_loan_status'>
                  <Image src={IconkYC} />
                  <Heading as="h3" className='font-poppins'>
                    Your loan application has been created. Please wait for 3 days to apply for a new loan.{' '}
                    <Link to={routesNames.BORROWERLOANSTATUS}>Check Loan Status</Link>
                  </Heading> 
                </Box> : 
              // isLoanRejected === 0 ? 
              //   <Box mt={4} className='check_loan_reject'>
              //     <Image src={RejectedIcon} />
              //     <Heading as="h3" className='font-poppins'>
              //       You're not eligible for a loan at this time. Please try again in 90 days.
              //     </Heading> 
              //   </Box> : 
              null 
              }
            </GridItem>
          </Grid>
          <Grid className="main_grid_container">
            <GridItem colSpan={7}>
              <Box mt={5}>
                <Heading as="h2" className='font-poppins block_heading' mb={3}>Loan Offerings</Heading>
                <LoanCard productData={productData} handleLoans={handleLoans} productId={productId} onChangeFile={onChangeFile}
                  showOpenFileDialog={showOpenFileDialog} bankStatement={bankStatement} incomeStatement={incomeStatement}
                  itrStatement={itrStatement} bankRef={bankRef} incomeRef={incomeRef} itrRef={itrRef} expectedROI={expectedROI}
                  handleExpectedROI={handleExpectedROI} dropdownData={dropdownData} handleApplyLoan={handleApplyLoan}
                  handleChange={handleChange} inputValues={inputValues} isLoanApplied={isLoanApplied} isKYCPending={isKYCPending}
                  isKYCUnderReview={isKYCUnderReview} userData={userData} handleLoanTenure={handleLoanTenure} loanTenure={loanTenure}
                  loanRange={loanRange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={5} mt={5}>
              <Box className='sticky_block'>
                <Heading as="h2" className='font-poppins block_heading' mb={3}>Emi Calculator </Heading>
                <Box className='emi_block bg_shadow'>
                  <Tabs className='tabs_container' index={activeTab} onChange={handleTabChange}>
                    <TabList className='tabs_list'>
                      {tabNames?.map((item, index) => {
                          return (
                              <Tab key={index}>{item.name}</Tab>
                          )
                      })}
                    </TabList>
                    <TabPanels>
                      {tabNames.map((item, index) => {
                        return (
                          <TabPanel key={index} className='tab_panel'>
                            {item.name === 'Insta Loan' && 
                              <InstaLoanEmiCalculator handleChangeInstaLoan={handleChangeInstaLoan} emiCalculator={emiCalculator}
                                instaLoanDetails={instaLoanDetails} isInstaLoan={isInstaLoan}
                              />
                            }
                            {item.name === 'Bullet Loan' &&
                              <BulletLoanEmiCalculator handleChangeBulletLoan={handleChangeBulletLoan} emiCalculator={emiCalculator}
                                bulletLoanDetails={bulletLoanDetails} isBulletLoan={isBulletLoan}
                              />
                            }
                            {item.name === 'Personal Loan' &&
                              <PersonalLoanEmiCalculator handleChangePersonalLoan={handleChangePersonalLoan} emiCalculator={emiCalculator}
                                personalLoanDetails={personalLoanDetails} isPersonalLoan={isPersonalLoan}
                              />
                            }
                          </TabPanel>
                        )
                      })}
                    </TabPanels>
                  </Tabs>
                </Box>
              </Box>
            </GridItem>
          </Grid>
      </Box>
      <LoanAppliedDialog loanSuccess={loanSuccess} handleCloseLoanSuccess={handleCloseLoanSuccess}
        handleGoToLoanStatus={handleGoToLoanStatus}
      />
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(ApplyLoan)